import React from 'react'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import FormReviewTable from 'core/components/validatedForm/review-table'
import { findClusterName } from 'k8s/util/helpers'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { useSelector } from 'react-redux'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import Progress from 'core/components/progress/Progress'
import jsYaml from 'js-yaml'
import Accordion from 'core/components/accordion/Accordion'

const diskSourceLabels = {
  httpUrl: 'Image from HTTP URL',
  registryUrl: 'Image from Registry URL',
  disk: 'Image from Disk',
  clone: 'Clone PVC',
  volume: 'Attach Data Volume',
  existing: ' Existing PVC',
  blank: 'Create Blank Disk',
}

const diskSourceValues = {
  httpUrl: (disk) => {
    return `: ${disk.httpUrl}`
  },
  registryUrl: (disk) => {
    return `: ${disk.registryUrl}`
  },
  disk: (disk) => {
    return ''
  },
  clone: (disk) => {
    return `: ${disk.selectedPvcs?.[0]?.name}`
  },
  volume: (disk) => {
    return `: ${disk.selectedDataVolumes?.[0]?.name}`
  },
  existing: (disk) => {
    return `: ${disk.selectedPvcs?.[0]?.name}`
  },
  blank: (disk) => {
    return ''
  },
}

const RenderClusterName = ({ clusterId }) => {
  const { loading } = useListAction(listClusters)
  const clusters = useSelector(clustersSelector)
  const clusterName = findClusterName(clusters, clusterId)
  return (
    <Progress inline loading={loading} overlay={false} message={null}>
      <span>{clusterName}</span>
    </Progress>
  )
}

const RenderBulkVmNames = ({ names }) => {
  return (
    <>
      {names.map((name) => (
        <div key={name}>{name}</div>
      ))}
    </>
  )
}

const RenderStorageDisks = ({ storageDisks }) => {
  return (
    <>
      {storageDisks.map((disk, idx) => (
        <div key={disk.rowId}>
          {idx + 1}. {diskSourceLabels[disk.sourceType]}
          {diskSourceValues[disk.sourceType](disk)}
        </div>
      ))}
    </>
  )
}

const RenderNetworks = ({ networks }) => {
  return (
    <>
      {networks.map((network) => (
        <div key={network.name}>{network.name}</div>
      ))}
    </>
  )
}

const basicColumns = [
  {
    id: 'metadata.name',
    label: 'VM Name(s)',
    jointRender: (value, data) => <RenderBulkVmNames names={[value, ...data.names]} />,
  },
  { id: 'clusterId', label: 'Cluster', render: (value) => <RenderClusterName clusterId={value} /> },
  { id: 'namespace', label: 'Namespace' },
  {
    id: 'spec.runStrategy',
    label: 'Run Strategy',
  },
]

const storageColumns = [
  {
    id: 'storageDisks',
    label: 'Storage Sources',
    renderArray: true,
    render: (value) => <RenderStorageDisks storageDisks={value} />,
  },
]

const resourceColumns = [
  {
    id: 'instanceType',
    label: 'Instance Type',
    renderArray: true,
    render: (value) => value?.[0]?.name,
    hide: (wizardContext) => wizardContext.resourceOption === 'custom',
  },
  {
    id: 'spec.template.spec.domain.cpu.cores',
    label: 'VCPUs',
    hide: (wizardContext) => wizardContext.resourceOption === 'useInstanceType',
  },
  {
    id: 'spec.template.spec.domain.memory.guest',
    label: 'RAM',
    hide: (wizardContext) => wizardContext.resourceOption === 'useInstanceType',
  },
]

const networkColumns = [
  {
    id: 'podNetworkBindingType',
    label: 'Pod Network Binding Type',
  },
  {
    id: 'selectedNetworks',
    label: 'Networks',
    renderArray: true,
    render: (value) => <RenderNetworks networks={value} />,
  },
]

const cloudInitColumns = [
  {
    id: 'cloudInit',
    label: 'Cloud-Init Script',
  },
]

const ReviewStep = ({ wizardContext }) => {
  const yamlJson = jsYaml.load(wizardContext.advancedYaml)
  const combinedJson = {
    ...wizardContext,
    ...yamlJson,
  }

  return (
    <FormFieldSection title="Finalize & Review">
      <Accordion id="basic" title="Basic Settings">
        <FormReviewTable data={combinedJson} columns={basicColumns} />
      </Accordion>
      <Accordion id="storage" title="Storage">
        <FormReviewTable data={combinedJson} columns={storageColumns} />
      </Accordion>
      <Accordion id="resource" title="Resource">
        <FormReviewTable data={combinedJson} columns={resourceColumns} />
      </Accordion>
      <Accordion id="network" title="Network">
        <FormReviewTable data={combinedJson} columns={networkColumns} />
      </Accordion>
      <Accordion id="cloudinit" title="Cloud-Init">
        <FormReviewTable data={combinedJson} columns={cloudInitColumns} />
      </Accordion>
    </FormFieldSection>
  )
}

export default ReviewStep
