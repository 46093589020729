import React from 'react'
import ProductInfoPage from 'core/containers/ProductInfoPage'
import Text from 'core/elements/Text'
import CardButton from 'core/elements/button/CardButton'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import useToggler from 'core/hooks/useToggler'
import InstallKubevirtForm from './InstallKubevirtForm'
import { trackEvent } from 'utils/tracking'

import { KubeVirtLogo } from '../logo'

export default function InformationalLandingPage() {
  const { history } = useReactRouter()
  const [modalOpen, toggleModalOpen] = useToggler(false)
  return (
    <>
      <InstallKubevirtForm open={modalOpen} onClose={toggleModalOpen} />
      <ProductInfoPage
        title="Run VMs in your Kubernetes Environment with KubeVirt"
        footerTitle="Choose where to enable KubeVirt"
        icon={<KubeVirtLogo width="240" height="240" />}
        actions={[
          <CardButton
            key="kubevirt-choose-new"
            icon="plus"
            title="Create a New Cluster"
            message="Configure Add-ons to enable Kubevirt"
            onClick={() => {
              trackEvent('Clicked Create New Kubevirt Cluster')
              history.push(routes.cluster.add.root.path())
            }}
          />,
          <CardButton
            key="kubevirt-choose-existing"
            icon="check-double"
            title="Add KubeVirt configuration to a selected cluster"
            message="Add KubeVirt configuration to a selected cluster"
            onClick={() => {
              trackEvent('Clicked Add Kubevirt Configuration to Selected Cluster')
              toggleModalOpen()
            }}
          />,
        ]}
      >
        <Text variant="body1">
          Kubevirt brings your VMs to Kubernetes to run both legacy and cloud-native applications on
          Platform9.
        </Text>
        <Text variant="body1">
          Add Kubevirt to any cluster and run VMs natively in kubernetes alongside containers.
        </Text>
      </ProductInfoPage>
    </>
  )
}
