import React, { useEffect } from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'core/elements/Text'
import { codeMirrorOptions } from 'app/constants'
import { yamlValidator } from 'core/utils/fieldValidators'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import jsYaml from 'js-yaml'
import { getVirtualMachineJson, getVolumeJson } from './helpers'
import { update } from 'ramda'

const useStyles = makeStyles<Theme>((theme) => ({
  fullWidth: {
    width: '100% !important',
    maxWidth: '50vw',
  },
}))

const yamlValidations = [yamlValidator]

export const AdvancedConfigurationStep = ({ wizardContext, setWizardContext, onNext }) => {
  const classes = useStyles({})

  useEffect(() => {
    if (!wizardContext.advancedYaml) {
      const advancedJson = getVirtualMachineJson({
        name: wizardContext?.name,
        runStrategy: wizardContext?.runStrategy,
        diskSpec: wizardContext?.diskTemplatesObj,
        volumeSpec: wizardContext?.volumesObj,
        dataVolumeTemplates: wizardContext?.dataVolumeTemplates,
        cpuSpec: wizardContext?.cpuTemplateObj,
        memorySpec: wizardContext?.memoryTemplateObj,
        interfaceSpec: wizardContext?.interfacesObj,
        networkSpec: wizardContext?.networksObj,
        instanceTypeSpec: wizardContext?.instanceTypeTemplateObj,
      })
      const advancedYaml = jsYaml.dump(advancedJson)

      const multiVmYamls = wizardContext?.names?.map((name) => {
        const { diskSpec, volumeSpec, dataVolumeTemplates } = getVolumeJson({
          storageDisks: wizardContext.storageDisks,
          vmName: name,
          cloudInit: wizardContext.cloudInit,
        })
        return {
          name,
          yaml: jsYaml.dump(
            getVirtualMachineJson({
              name,
              runStrategy: wizardContext?.runStrategy,
              diskSpec,
              volumeSpec,
              dataVolumeTemplates: dataVolumeTemplates?.length ? dataVolumeTemplates : null,
              cpuSpec: wizardContext?.cpuTemplateObj,
              memorySpec: wizardContext?.memoryTemplateObj,
              interfaceSpec: wizardContext?.interfacesObj,
              networkSpec: wizardContext?.networksObj,
              instanceTypeSpec: wizardContext?.instanceTypeTemplateObj,
            }),
          ),
        }
      })
      setWizardContext({
        advancedYaml,
        multiVmYamls,
      })
    }
  }, [])

  return (
    <ValidatedForm initialValues={wizardContext} triggerSubmit={onNext} elevated={false}>
      <FormFieldSection title="Advanced Configuration">
        <Text>Enter or edit value details below.</Text>
        <CodeMirror
          id="advancedYaml"
          label={`YAML Resource (${wizardContext?.name})`}
          validations={yamlValidations}
          onChange={(value) => setWizardContext({ advancedYaml: value })}
          value={wizardContext.advancedYaml}
          options={codeMirrorOptions}
          className={classes.fullWidth}
          showCollapseButton
        />
        {wizardContext?.multiVmYamls?.map((data, idx) => (
          <CodeMirror
            key={data?.name}
            id={`multiVmYamls.${idx}.yaml`}
            label={`YAML Resource (${data?.name})`}
            validations={yamlValidations}
            onChange={(value) => {
              const updatedYaml = {
                ...data,
                yaml: value,
              }
              setWizardContext({
                multiVmYamls: update(idx, updatedYaml, wizardContext?.multiVmYamls),
              })
            }}
            value={data?.yaml}
            options={codeMirrorOptions}
            className={classes.fullWidth}
            showCollapseButton
          />
        ))}
      </FormFieldSection>
    </ValidatedForm>
  )
}

export default AdvancedConfigurationStep
