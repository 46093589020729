import React from 'react'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { hexToRgbaCss } from 'core/utils/colorHelpers'

interface Props {
  label: string
  icon: string
  onClick: (val) => void
}

const iconSrc = {
  upload: '/ui/images/cloud-upload@3x.png',
  wizard: '/ui/images/hat-wizard@3x.png',
}

export default function MethodCard({ label, icon, onClick }: Props) {
  const classes = useStyles()
  return (
    <div onClick={onClick} className={classes.card}>
      <div className={classes.iconBackground}>
        <img className={classes.icon} src={iconSrc[icon]} />
      </div>
      <Text variant="subtitle2">{label}</Text>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.components.selectableCard.activeBorder}`,
    },
    border: `1px solid ${theme.components.selectableCard.border}`,
    background: theme.components.card.background,
    borderRadius: theme.spacing(0.5),
    width: 400,
    gap: 16,
    padding: 32,
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    alignItems: 'center',
  },
  icon: {
    width: 16,
    height: 16,
  },
  iconBackground: {
    padding: 8,
    background: theme.components.badge.primary.background,
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
  },
}))
