import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

const options = [
  { label: 'ReadWriteOnce', value: 'ReadWriteOnce' },
  { label: 'ReadWriteMany', value: 'ReadWriteMany' },
]

interface Props {}

export default function AccessModePicklist({
  name = 'accessMode',
  label = 'Access Mode',
  compact = false,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return (
    <AsyncDropdown
      compact={compact}
      selectFirst
      name={name}
      label={label}
      items={options}
      {...rest}
    />
  )
}
