import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {}

// values match with moment's add/subtract API for use in actions.js
const items = [
  { label: 'Import an Image from HTTP URL', value: 'httpUrl' },
  { label: 'Import an Image from Registry URL', value: 'registryUrl' },
  { label: 'Upload an Image from Disk', value: 'disk' },
  { label: 'Clone Existing PVC', value: 'clone' },
  { label: 'Attach Existing Disk', value: 'volume' },
  { label: 'Create Blank Disk', value: 'blank' },
]

export default function VmStorageOptionPicklist({
  name = 'sourceType',
  label = 'Select an Option',
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown name={name} label={label} items={items} {...rest} />
}
