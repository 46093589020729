import React, { useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { keys, pick, uniq } from 'ramda'
import { routes } from 'core/utils/routes'
import Text from 'core/elements/Text'
import { VirtualMachineResourceTypes, IVirtualMachineDetailsPageTabs } from './model'
import { IVirtualMachineInstanceDetailsPageTabs } from './vmi-model'
import DataKeys from 'k8s/DataKeys'
import { listVirtualMachines, listVirtualMachineInstances, listLiveMigrations } from './new-actions'
import { allVmsSelector } from './selectors'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import DocumentMeta from 'core/components/DocumentMeta'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import InferActionParams from 'core/actions/InferActionParams'
import { GuestOS } from './vmi-model'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import { BadgeVariant } from 'core/elements/badge/Badge'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import ApiClient from 'api-client/ApiClient'
import PollingData from 'core/components/PollingData'
import { GridDropdownBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import BulkActionDialog from './BulkActionDialog'
import LabelsMultiDropdownFilter from 'core/elements/grid/filters/LabelsMultiDropdownFilter'
import StringMultiDropdownFilter from 'core/elements/grid/filters/StringMultiDropdownFilter'

const oneSecond = 1000
const { qbert } = ApiClient.getInstance()

type ModelDataKey = DataKeys.VirtualMachines | DataKeys.VirtualMachineInstances
type SelectorModel = ArrayElement<ReturnType<typeof allVmsSelector>>
type ActionParams =
  | InferActionParams<typeof listVirtualMachines>
  | InferActionParams<typeof listVirtualMachineInstances>

type Params = ActionParams & {
  clusterId?: string
  namespace?: string
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  namespace: null,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'VirtualMachines',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName', 'vmi.status.nodeName']

// TODO: get all possible status for VM and VMI and split the function
export const getVmStatus = (status) => {
  let variant: BadgeVariant = 'warning'
  // Rest of the statuses should get warning status
  switch (status) {
    case 'Running':
      variant = 'success'
      break
    case 'Terminating':
    case 'CrashLoopBackOff':
    case 'ErrorUnschedulable':
    case 'ErrImagePull':
    case 'ImagePullBackOff':
    case 'ErrorPvcNotFound':
    case 'ErrorDataVolumeNotFound':
    case 'DataVolumeError':
    case 'WaitingForVolumeBinding':
    case 'Stopped':
      variant = 'error'
      break
  }
  return { variant }
}

export const getVmiStatus = (status, paused = false) => {
  let variant: BadgeVariant = 'warning'
  if (paused) {
    return { variant }
  }
  // Rest of the statuses should get warning status
  switch (status) {
    case 'Running':
      variant = 'success'
      break
    case 'Failed':
      variant = 'error'
      break
  }
  return { variant }
}

const NetworksCell = ({ value = [] }) => {
  return (
    <div>
      {value.map((network) => (
        <Text variant="body2" key={network.networkName}>
          <div>
            <b>Network</b>: {network.networkName}
          </div>
          <div>
            <b>IP Address</b>: {network.ipAddress}
          </div>
          <div>
            <b>MAC Address</b>: {network.mac}
          </div>
        </Text>
      ))}
    </div>
  )
}

const HostCell = ({ value, item }) => {
  const classes = useStyles()
  const migrationState = item?.vmi?.status?.migrationState
  const isMigrating = !!migrationState && !migrationState?.completed
  return isMigrating ? (
    <div className={classes.hostCell}>
      <FontAwesomeIcon className={classes.spinner} size="md" solid spin>
        circle-notch
      </FontAwesomeIcon>
      <div>Migrating</div>
    </div>
  ) : (
    <Text variant="body2">{value}</Text>
  )
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterId, vm, vmi }) =>
        vm
          ? routes.virtualMachines.details.path({
              clusterId,
              id: vm?.id,
              tab: IVirtualMachineDetailsPageTabs.Overview,
            })
          : vmi
          ? routes.virtualMachineInstances.details.path({
              clusterId,
              name: vmi?.name,
              tab: IVirtualMachineInstanceDetailsPageTabs.Overview,
            })
          : '',
    }),
  },
  {
    key: 'vm.status.printableStatus',
    label: 'VM Status',
    CellComponent: ({ ...props }) => {
      if (!props.item.vm) {
        return null
      }
      return createGridStatusCell({
        dataFn: getVmStatus,
      })(props)
    },
  },
  {
    key: 'vmi.status.phase',
    label: 'VMI Phase',
    CellComponent: ({ ...props }) => {
      if (!props.item.vmi) {
        return null
      }
      return createGridStatusCell({
        dataFn: getVmiStatus,
      })(props)
    },
  },
  {
    key: 'vmi.networks',
    label: 'Networks',
    CellComponent: NetworksCell,
  },
  {
    key: 'vmi.status.guestOSInfo',
    label: 'Guest OS',
    formatFn: (val: GuestOS) => {
      return val?.prettyName || val?.name
    },
  },
  {
    key: 'clusterName',
    label: 'Cluster',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterId }) => routes.cluster.managed.qbert.detail.path({ id: clusterId }),
    }),
  },
  {
    key: 'vmi.status.nodeName',
    label: 'Node',
    CellComponent: HostCell,
  },
  { key: 'namespace', label: 'Namespace' },
  {
    key: 'vm.metadata.labels',
    label: 'Labels',
    disableSorting: true,
    CellComponent: createResourceLabelsCell({
      type: 'table',
      separator: '=',
    }),
  },
]

const getLabelOptions = (item) => {
  const itemLabels = item?.vm?.metadata?.labels || {}
  const labelKeys = keys(itemLabels)
  return labelKeys.map((key) => ({
    key,
    value: itemLabels[key],
  }))
}

const getNodeOptions = (item) => {
  return item?.vmi?.status?.nodeName
    ? [{ label: item?.vmi?.status?.nodeName, value: item?.vmi?.status?.nodeName }]
    : []
}

const getVmStatusOptions = (item) => {
  return item?.vm?.status?.printableStatus
    ? [{ label: item?.vm?.status?.printableStatus, value: item?.vm?.status?.printableStatus }]
    : []
}

const dropdownFilters = [
  {
    key: 'status',
    label: 'VM Status',
    FilterComponent: StringMultiDropdownFilter,
    filterComponentProps: {
      label: 'VM Status',
    },
    filterComponentOptionsPropName: 'dropdownOptions',
    getOptionsFn: (items) => {
      const options = items?.reduce((accum, item) => {
        const statuses = getVmStatusOptions(item)
        return [...accum, ...statuses]
      }, [])
      return uniq(options)
    },
    equalityComparerFn: (item, value) => {
      const hasSome = value?.some((status) => {
        return item?.vm?.status?.printableStatus === status
      })
      return hasSome
    },
  },
  {
    key: 'nodes',
    label: 'Nodes',
    FilterComponent: StringMultiDropdownFilter,
    filterComponentProps: {
      label: 'Nodes',
    },
    filterComponentOptionsPropName: 'dropdownOptions',
    getOptionsFn: (items) => {
      const options = items?.reduce((accum, item) => {
        const nodes = getNodeOptions(item)
        return [...accum, ...nodes]
      }, [])
      return uniq(options)
    },
    equalityComparerFn: (item, value) => {
      const hasSome = value?.some((node) => {
        return item?.vmi?.status?.nodeName === node
      })
      return hasSome
    },
  },
  {
    key: 'labels',
    label: 'Labels',
    FilterComponent: LabelsMultiDropdownFilter,
    filterComponentProps: {},
    filterComponentOptionsPropName: 'labels',
    getOptionsFn: (items) => {
      const options = items?.reduce((accum, item) => {
        const labels = getLabelOptions(item)
        return [...accum, ...labels]
      }, [])
      return uniq(options)
    },
    equalityComparerFn: (item, value) => {
      const hasSome = value?.some((label) => {
        const itemLabels = item?.vm?.metadata?.labels || {}
        return itemLabels[label?.key] === label?.value
      })
      return hasSome
    },
  },
]

export default function VirtualMachinesListPage() {
  const classes = useStyles()
  const { history } = useReactRouter()
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message: vmMessage, loading: loadingVms, reload: reloadVms } = useListAction(
    listVirtualMachines,
    {
      params,
      requiredParams,
    },
  )
  const { message: vmiMessage, loading: loadingVmis, reload: reloadVmis } = useListAction(
    listVirtualMachineInstances,
    {
      params,
      requiredParams,
    },
  )
  const data = useAppSelector(allVmsSelector)

  // Note:
  // useMemo is necessary so that dropdownBatchActions is not
  // created anew when this component rerendering. If a new
  // dropdownBatchActions is created when a dialog is open,
  // the dialog closes. Observed this problem when trying to
  // open a dialog for the first time on app load
  const dropdownBatchActions: GridDropdownBatchActionSpec<SelectorModel>[] = useMemo(
    () => [
      {
        label: 'Restart',
        BatchActionButton: getGridDialogButton(
          BulkActionDialog,
          { action: 'restart' },
          { className: classes.dropdownAction },
        ),
      },
      {
        label: 'Start',
        BatchActionButton: getGridDialogButton(
          BulkActionDialog,
          { action: 'start' },
          { className: classes.dropdownAction },
        ),
      },
      {
        label: 'Stop',
        BatchActionButton: getGridDialogButton(
          BulkActionDialog,
          { action: 'stop' },
          { className: classes.dropdownAction },
        ),
      },
      {
        label: 'Pause',
        BatchActionButton: getGridDialogButton(
          BulkActionDialog,
          { action: 'pause' },
          { className: classes.dropdownAction },
        ),
      },
      {
        label: 'Unpause',
        BatchActionButton: getGridDialogButton(
          BulkActionDialog,
          { action: 'unpause' },
          { className: classes.dropdownAction },
        ),
      },
      {
        label: 'Live Migrate',
        BatchActionButton: getGridDialogButton(
          BulkActionDialog,
          { action: 'liveMigration' },
          { className: classes.dropdownAction },
        ),
      },
      {
        label: 'Delete',
        BatchActionButton: getGridDialogButton(
          BulkActionDialog,
          { action: 'delete' },
          { className: classes.deleteAction },
        ),
      },
    ],
    [classes],
  )

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      // Could also consider only allowing action on Running VMs
      cond: (item) => {
        return !!item?.vm && item?.vm?.status?.printableStatus !== 'Stopped'
      },
      label: 'Restart',
      icon: 'power-off',
      handleClick: (item) => {
        return qbert.virtualMachinePowerOperation(
          item?.vm?.clusterId,
          item?.vm?.namespace,
          item?.vm?.name,
          'restart',
        )
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
    {
      cond: (item) => {
        return !!item?.vm
      },
      label: 'Start',
      icon: 'play',
      handleClick: (item) => {
        return qbert.virtualMachinePowerOperation(
          item?.vm?.clusterId,
          item?.vm?.namespace,
          item?.vm?.name,
          'start',
        )
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
    {
      cond: (item) => {
        return !!item?.vm
      },
      label: 'Stop',
      icon: 'stop',
      handleClick: (item) => {
        return qbert.virtualMachinePowerOperation(
          item?.vm?.clusterId,
          item?.vm?.namespace,
          item?.vm?.name,
          'stop',
        )
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
    {
      cond: (item) => {
        return !item?.vm
      },
      label: 'Pause',
      icon: 'pause',
      handleClick: (item) => {
        return qbert.vmiPowerOperation(
          item?.vmi?.clusterId,
          item?.vmi?.namespace,
          item?.vmi?.name,
          'pause',
        )
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
    {
      cond: (item) => {
        return !item?.vm
      },
      label: 'Unpause',
      icon: 'play',
      handleClick: (item) => {
        return qbert.vmiPowerOperation(
          item?.vmi?.clusterId,
          item?.vmi?.namespace,
          item?.vmi?.name,
          'unpause',
        )
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
    {
      cond: (item) => {
        if (!item?.vmi) {
          return false
        }
        const condition = item?.vmi?.status?.conditions?.find(
          (cond) => cond.type === 'LiveMigratable',
        )
        return condition?.status === 'True'
      },
      label: 'Live Migration',
      icon: 'server',
      handleClick: (item) => {
        const timestamp = new Date().getTime()
        const body = {
          apiVersion: 'kubevirt.io/v1',
          kind: 'VirtualMachineInstanceMigration',
          metadata: {
            name: `${item?.vmi?.name}-${timestamp}`,
          },
          spec: {
            vmiName: item?.vmi?.name,
          },
        }
        return qbert.virtualMachineInstanceMigration(
          item?.vmi?.clusterId,
          item?.vmi?.namespace,
          body,
        )
      },
      refreshAfterSuccess: true,
      onComplete: (success, item) => {
        if (!success) {
          return
        }
        listLiveMigrations.call({ clusterId: item?.vmi?.clusterId })
      },
    },
    {
      cond: () => true,
      label: 'Edit',
      icon: 'edit',
      handleClick: (item) => {
        if (item?.vm) {
          history.push(
            routes.virtualMachines.details.path({
              clusterId: item?.vm?.clusterId,
              id: item?.vm?.id,
              tab: IVirtualMachineDetailsPageTabs.Yaml,
            }),
          )
          return
        }
        history.push(
          routes.virtualMachineInstances.details.path({
            clusterId: item?.vmi?.clusterId,
            name: item?.vmi?.name,
            tab: IVirtualMachineInstanceDetailsPageTabs.Yaml,
          }),
        )
        return
      },
      refreshAfterSuccess: false,
    },
    {
      cond: (item) => true,
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (item) => {
        if (item.vm) {
          return qbert.deleteVirtualMachine(
            item?.vm?.clusterId,
            item?.vm?.namespace,
            item?.vm?.name,
          )
        } else if (item.vmi) {
          return qbert.deleteVirtualMachineInstance(
            item?.vmi?.clusterId,
            item?.vmi?.namespace,
            item?.vmi?.name,
          )
        }
        return false
      },
      refreshAfterSuccess: true,
    },
  ]

  return (
    <>
      <DocumentMeta title="Virtual Machines" />
      <PollingData
        hidden
        loading={loadingVms}
        onReload={reloadVms}
        refreshDuration={oneSecond * 60}
      />
      <PollingData
        hidden
        loading={loadingVmis}
        onReload={reloadVmis}
        refreshDuration={oneSecond * 60}
      />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.VirtualMachines}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loadingVms || loadingVmis}
        loadingMessage={vmMessage || vmiMessage}
        onRefresh={() => {
          reloadVms(true, true)
          reloadVmis(true, true)
        }}
        data={data}
        columns={columns}
        // addUrl={routes.virtualMachineInstances.add.path({
        //   createType: VirtualMachineResourceTypes.VirtualMachine,
        // })}
        addUrl={routes.virtualMachines.create.path()}
        addText="Add Virtual Machine"
        getParamsUpdater={getParamsUpdater}
        dropdownBatchActions={dropdownBatchActions}
        rowMenuItems={rowMenuItems}
        // rowMenuOffset={{ vertical: 60 }}
        maxRowMenuHeight={150} // set a max row menu height with overflow scroll
        tooltip={
          <>
            <div>
              Virtual Machines provide management functions for VirtualMachineInstances in the
              cluster, such as powering VMIs, ensuring the startup state of VMIs, and have a 1:1
              relationship with VMIs.
            </div>
            <br />
            <div>
              Virtual Machine Instances are the minimum resource for managing VMs. A
              VirtualMachineInstance object represents a running instance of a VM and all of its
              configuration.
            </div>
          </>
        }
        dropdownFilters={dropdownFilters}
        multiSelection
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  hostCell: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: 8,
  },
  spinner: {
    color: theme.components.badge.primary.color,
  },
  dropdownAction: {
    background: theme.components.dropdown.background,
    border: 'none',
    '& .button-text': {
      color: theme.components.dropdown.color,
      justifyContent: 'start',
    },
  },
  deleteAction: {
    background: theme.components.dropdown.background,
    border: 'none',
    '& .button-text': {
      color: theme.components.dropdown.error,
      justifyContent: 'start',
    },
  },
}))
