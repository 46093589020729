import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Alert from 'core/components/Alert'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import ExternalLink from 'core/components/ExternalLink'
import Text from 'core/elements/Text'

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: '16px 24px',
    display: 'grid',
    gap: 16,
    background: theme.components.accordion.background,
  },
  bulletList: {
    display: 'grid',
    gap: 16,
    paddingInlineStart: 20,
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  alertTitle: {
    marginBottom: 10,
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    alignItems: 'center',
    gap: 8,
  },
  alertTitleIcon: {
    color: theme.components.badge.primary.color,
  },
}))

export const MethodHelp = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Text variant="subtitle2">Configure VM</Text>
      <Text variant="body2">
        You can create the VM using a YAML file, or use the new Create a New VM Instance wizard.
      </Text>
      <ul className={classes.bulletList}>
        <li>
          <Text variant="body2">
            <b>YAML file</b> - Users can now use a YAML file to define the predetermined VM
            configuration settings included in the file. This makes for more reliable replication,
            better readability and increased reliability.
          </Text>
        </li>
        <li>
          <Text variant="body2">
            Using the <b>VM Wizard</b> - With this option, you have the flexibility to input all the
            info specific to your needs using our UI.
          </Text>
        </li>
      </ul>
    </div>
  )
}

export const YamlHelp = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Alert
        variant="primary"
        title={
          <div className={classes.alertTitle}>
            <FontAwesomeIcon className={classes.alertTitleIcon} solid>
              circle-info
            </FontAwesomeIcon>
            <Text variant="caption1" component="h5">
              Note
            </Text>
          </div>
        }
        message={<Text variant="body2">Be sure to set the "kind" property to VirtualMachine.</Text>}
      />
      <Alert
        variant="warning"
        title={
          <div className={classes.alertTitle}>
            <FontAwesomeIcon className={classes.alertTitleIcon} solid>
              circle-info
            </FontAwesomeIcon>
            <Text variant="caption1" component="h5">
              Warning
            </Text>
          </div>
        }
        message={
          <Text variant="body2">
            Your YAML file must be syntactically correct, or it will throw an error message stating:
            Provided YAML code is invalid.
          </Text>
        }
      />
    </div>
  )
}

export const BasicHelp = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Text variant="subtitle2">VM and VMI differences</Text>
      <Text variant="body2">
        <b>VirtualMachine (VM)</b>: Provides management functions for VirtualMachineInstance in the
        cluster, such as powering on/off/restarting VMs, ensuring the startup state of VM instances,
        and has a 1:1 relationship with VM instances.
      </Text>
      <Text variant="body2">
        <b>VirtualMachineInstance (VMI)</b>: Similar to a kubernetes Pod, it is the minimum resource
        for managing virtual machines . A VirtualMachineInstance object represents a running
        instance of a virtual machine and contains all the configuration needed for a virtual
        machine.
      </Text>
    </div>
  )
}

export const NetworksHelp = () => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.container}>
        <Text variant="body2">
          You have two options when selecting the Binding Mode for Pod Network:
        </Text>
        <ul className={classes.bulletList}>
          <li>
            <Text variant="body2">
              <b>Masquerade</b> - In this mode, KubeVirt allocates internally NAT'd IP addresses to
              the image
            </Text>
          </li>
          <li>
            <Text variant="body2">
              <b>Bridge</b> - Here, virtual machines are linked to the network backend via a linux
              "bridge"
            </Text>
          </li>
        </ul>
      </div>
      <Alert
        variant="primary"
        title={
          <div className={classes.alertTitle}>
            <FontAwesomeIcon className={classes.alertTitleIcon} solid>
              circle-info
            </FontAwesomeIcon>
            <Text variant="caption1" component="h5">
              Note
            </Text>
          </div>
        }
        message={
          <Text variant="body2">
            Live migration is not allowed with a pod network binding of bridge interface type
          </Text>
        }
      />
    </>
  )
}

export const CloudInitHelp = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Text variant="body2">
        Kubevirt utilizes cloud-init as a sort of template, similar to a kubevirt VM or VMI yaml
        config. These can be a useful tool, especially when debugging test cases. Here, users can
        either enter a cloud-init script or upload a cloud-init file. More examples of cloud-init
        configurations can be found at{' '}
        <ExternalLink url="https://cloudinit.readthedocs.io/">
          https://cloudinit.readthedocs.io/
        </ExternalLink>
      </Text>
    </div>
  )
}
