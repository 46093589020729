import React, { useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import { makeParamsAllClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import { listVirtualMachines, listVirtualMachineInstances } from '../virtual-machines/new-actions'
import { useSelector } from 'react-redux'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { kubevirtEventsSelector } from './selectors'
import EntityEventsPage from 'k8s/components/common/entity/entity-events-page'

type Params = {
  clusterId: string | string[]
}

const defaultParams: Params = {
  clusterId: '',
}

const allClustersParams = {
  orderBy: 'name',
  kubevirtClusters: true,
}

const healthyClustersParams = {
  orderBy: 'name',
  kubevirtClusters: true,
  healthyClusters: true,
  ecoEnabledClusters: true,
}

const defaultParamsHealthyClusterSelector = makeParamsAllClustersSelector(healthyClustersParams)

export default function KubevirtDashboard() {
  const { params, updateParams } = useParams(defaultParams)
  const { loading: clustersLoading } = useListAction(listClusters)
  const { loading: importedClustersLoading } = useListAction(listImportedClusters)
  const healthyClusters = useSelector(defaultParamsHealthyClusterSelector)
  const [loadedHealthyClusters, setLoaded] = useState(false)

  useEffect(() => {
    // Clusters need to be loaded up before other list actions are made, otherwise,
    // won't be able to get the cluster IDs
    if (loadedHealthyClusters) {
      return
    }
    if (!clustersLoading && !importedClustersLoading) {
      const clusterIds = healthyClusters.map((cluster) => cluster.uuid)
      updateParams({ clusterId: clusterIds })
      setLoaded(true)
    }
  }, [clustersLoading, importedClustersLoading, healthyClusters, loadedHealthyClusters])

  const { loading: vmsLoading } = useListAction(listVirtualMachines, {
    params,
    requiredParams: ['clusterId'],
  })

  const { loading: vmisLoading } = useListAction(listVirtualMachineInstances, {
    params,
    requiredParams: ['clusterId'],
  })

  const eventEntities = useSelectorWithParams(kubevirtEventsSelector, {
    ...params,
    useGlobalParams: false,
  })

  return (
    <EntityEventsPage
      entity={eventEntities}
      loading={vmsLoading || vmisLoading}
      entityClusterIds={params?.clusterId}
      noMargin
    />
  )
}
