import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { keys } from 'ramda'
import Text from 'core/elements/Text'
import clsx from 'clsx'
import { uncamelizeString } from 'utils/misc'
import ProgressBar from 'core/components/progress/ProgressBar'

interface StyleProps {
  progressBar?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  resourceInfo: {
    padding: '16px 24px',
  },
  resourceProperties: {
    marginTop: 8,
    display: 'inline-grid',
    width: ({ progressBar }) => (progressBar ? '100%' : 'auto'),
    gridAutoFlow: 'column',
    gap: 24,
  },
  resourceProperty: {
    display: 'grid',
    gap: 4,
  },
  resourceKey: {
    textTransform: 'capitalize',
    color: theme.components.typography.passive,
  },
  progressBar: {
    justifySelf: 'end',
    alignSelf: 'center',
    width: '350px',
  },
}))

export default function ResourceInfo({
  type,
  fields,
  className = '',
  progressBar = false,
  percent = 0,
}) {
  const classes = useStyles({ progressBar })
  const fieldKeys = keys(fields)

  return (
    <div className={clsx(classes.resourceInfo, className)}>
      <Text variant="subtitle2">{type}</Text>
      <div className={classes.resourceProperties}>
        {fieldKeys.map((key) => (
          <div key={key.toString()} className={classes.resourceProperty}>
            <Text variant="caption1" className={classes.resourceKey}>
              {key}
            </Text>
            <Text variant="subtitle2">
              {typeof fields[key] === 'object'
                ? `${uncamelizeString(Object.keys(fields[key])[0])}: ${
                    Object.values(fields[key])[0]
                  }`
                : fields[key].toString() || '-'}
            </Text>
          </div>
        ))}
        {progressBar && (
          <div className={classes.progressBar}>
            <ProgressBar
              percent={percent}
              width={300}
              height={12}
              variant="health"
              label={`${percent.toFixed(2)}%`}
            />
          </div>
        )}
      </div>
    </div>
  )
}
