import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import { trackEvent } from 'utils/tracking'
import { routes } from 'core/utils/routes'
import Bugsnag from 'utils/bugsnag'
import { deleteVirtualMachineInstance } from './new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Modal from 'core/elements/modal'

const VirtualMachineInstanceDeleteDialog = ({ rows: [virtualMachine], onClose }) => {
  const { history } = useReactRouter()
  const { update: deleteFn, updating: deletingVirtualMachineInstance } = useUpdateAction(
    deleteVirtualMachineInstance,
  )

  const title = `Permanently delete VM Instance "${virtualMachine?.name}"?`
  const handleDelete = useCallback(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to delete VM Instance', {
      cluster_uuid: virtualMachine?.clusterId,
      vm_name: virtualMachine?.name,
      vm_namespace: virtualMachine?.namespace,
    })
    await deleteFn(virtualMachine)
    trackEvent('Delete VM Instance', {
      cluster_uuid: virtualMachine?.clusterId,
      vm_name: virtualMachine?.name,
      vm_namespace: virtualMachine?.namespace,
    })
    onClose(true)
    history.push(routes.kubevirt.overview.path())
  }, [virtualMachine])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={title}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingVirtualMachineInstance}>
            Don't Delete
          </Button>
          <Button onClick={handleDelete} loading={deletingVirtualMachineInstance}>
            Delete VM Instance
          </Button>
        </>
      }
    >
      <Text variant="body2">
        Please confirm you want to delete this VM Instance. This action cannot be undone.
      </Text>
    </Modal>
  )
}

export default VirtualMachineInstanceDeleteDialog
