import { routes } from 'core/utils/routes'
import VirtualMachinePowerOperationDialog from './VirtualMachinePowerOperationDialog'
import VmiPowerOperationDialog from './VmiPowerOperationDialog'

// export const batchActions = [
//   {
//     icon: 'power-off',
//     label: 'Power',
//     cond: () => false,
//     disabledInfo: () => 'Coming Soon.',
//     dialog: PowerVirtualMachineDialog,
//   },
//   {
//     icon: 'terminal',
//     label: 'Console',
//     dialog: null,
//     cond: () => false,
//     disabledInfo: () => 'Coming Soon.',
//   },
//   {
//     icon: 'chart-bar',
//     label: 'Monitoring',
//     dialog: null,
//     cond: () => false,
//     disabledInfo: () => 'Coming Soon.',
//   },
//   {
//     icon: 'suitcase',
//     label: 'Migrate',
//     dialog: null,
//     cond: () => false,
//     disabledInfo: () => 'Coming Soon.',
//   },
//   {
//     icon: 'edit',
//     label: 'Edit',
//     dialog: null,
//     cond: () => false,
//     disabledInfo: () => 'Coming Soon.',
//   },
//   {
//     icon: 'trash-alt',
//     label: 'Delete',
//     dialog: VirtualMachineInstanceDeleteDialog,
//   },
// ]

export const vmiBatchActions = [
  {
    icon: 'play',
    label: 'Unpause',
    dialog: VmiPowerOperationDialog,
    params: {
      operation: 'unpause',
    },
  },
  {
    icon: 'pause',
    label: 'Pause',
    dialog: VmiPowerOperationDialog,
    params: {
      operation: 'pause',
    },
  },
]

export const vmBatchActions = [
  {
    icon: 'play',
    label: 'Start',
    dialog: VirtualMachinePowerOperationDialog,
    cond: ([vm]) => !!vm?.spec?.runStrategy,
    disabledInfo: () => 'Run strategy is required to use power operations',
    params: {
      operation: 'start',
    },
  },
  {
    icon: 'stop',
    label: 'Stop',
    dialog: VirtualMachinePowerOperationDialog,
    cond: ([vm]) => {
      return !!vm?.spec?.runStrategy
    },
    disabledInfo: () => 'Run strategy is required to use power operations',
    params: {
      operation: 'stop',
    },
  },
  {
    icon: 'redo',
    label: 'Restart',
    cond: ([vm]) => !!vm?.spec?.runStrategy,
    disabledInfo: () => 'Run strategy is required to use power operations',
    dialog: VirtualMachinePowerOperationDialog,
    params: {
      operation: 'restart',
    },
  },
  {
    icon: 'edit',
    label: 'Edit',
    routeTo: (rows) =>
      routes.virtualMachines.edit.path({ id: rows[0].id, clusterId: rows[0].clusterId }),
  },
]
