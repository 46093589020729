import React, { useCallback, useEffect, useState } from 'react'
import ModalForm from 'core/elements/modal/ModalForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'core/elements/Text'
import PicklistField from 'core/components/validatedForm/DropdownField'
import useParams from 'core/hooks/useParams'
import NetworkPicklist from './NetworkPicklist'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { updateVirtualMachine } from '../new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: '16px 32px 0px 24px',
  },
  interfaceLabel: {
    display: 'inline-block',
    marginBottom: 8,
  },
  networkField: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto min-content',
  },
  addLabel: {
    width: 'fit-content',
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 8,
  },
  minusIcon: {
    color: theme.components.badge.primary.color,
    position: 'relative',
    top: 16,
    marginLeft: 16,
    cursor: 'pointer',
  },
  plusIcon: {
    color: theme.components.badge.primary.color,
  },
}))

export default function EditEntityNetworksModal({ vm, open, onClose }) {
  const classes = useStyles()
  const [loaded, setLoaded] = useState(false)
  const [baseNetworkIndex, setBaseNetworkIndex] = useState(0)
  const defaultParams = {
    existingNetworks: [],
    newNetworks: [],
  }

  const { params, updateParams, setParams } = useParams<{
    existingNetworks?: { interfaceName: string; networkName: string }[]
    newNetworks?: { interfaceName: string; networkName: string }[]
  }>(defaultParams)

  const { update: updateFn, updating, error, reset } = useUpdateAction(updateVirtualMachine)

  useEffect(() => {
    // Prevent resetting of form from background reloads
    if (loaded) {
      return
    }
    if (vm) {
      const networks = vm?.spec?.template?.spec?.networks
      const interfaces = vm?.spec?.template?.spec?.domain?.devices?.interfaces
      const networkData = networks.reduce(
        (accum, network) => {
          // treat pod network differently from other networks
          if (network.hasOwnProperty('pod')) {
            return {
              ...accum,
              podNetwork: network.name,
            }
          } else if (network.hasOwnProperty('multus')) {
            return {
              ...accum,
              existingNetworks: [
                ...accum.existingNetworks,
                { interfaceName: network.name, networkName: network?.multus?.networkName },
              ],
            }
          }
        },
        { existingNetworks: [], podNetwork: undefined },
      )

      // UI checks for existing networks with default UI naming convention
      // Get highest existing index and start new interface names above that
      const existingIndexes = networkData?.existingNetworks.map((network) => {
        const nameParts = network?.interfaceName?.split('-')
        if (!nameParts) {
          return 0
        }
        const index = parseInt(nameParts[nameParts.length - 1])
        return Number.isInteger(index) ? index : 0
      })
      const highestIndex = existingIndexes.sort().reverse()[0] || 0
      setBaseNetworkIndex(highestIndex + 1)
      updateParams({
        existingNetworks: networkData.existingNetworks,
      })
      setLoaded(true)
    }
  }, [vm, loaded])

  const getLastNetworkIndex = (networks) => {
    if (!networks.length) {
      return 0
    }
    const lastNetwork = networks[networks.length - 1]
    const nameParts = lastNetwork?.interfaceName?.split('-')
    if (!nameParts) {
      return 0
    }
    const index = parseInt(nameParts[nameParts.length - 1])
    return index
  }

  const removeExistingNetwork = useCallback(
    (network) => {
      const filteredNetworks = params?.existingNetworks.filter(
        (nw) => nw.interfaceName !== network.interfaceName,
      )
      updateParams({
        existingNetworks: filteredNetworks,
      })
    },
    [params?.existingNetworks, updateParams],
  )

  const removeNewNetwork = useCallback(
    (network) => {
      const filteredNetworks = params?.newNetworks.filter(
        (nw) => nw.interfaceName !== network.interfaceName,
      )
      updateParams({
        newNetworks: filteredNetworks,
      })
    },
    [params?.newNetworks, updateParams],
  )

  const addNewNetwork = useCallback(() => {
    const newIndex = params?.newNetworks?.length
      ? getLastNetworkIndex(params.newNetworks) + 1
      : baseNetworkIndex
    updateParams({
      newNetworks: [
        ...params.newNetworks,
        { interfaceName: `${vm?.name}-interface-${newIndex}`, networkName: undefined },
      ],
    })
  }, [baseNetworkIndex, params, updateParams])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  const submitForm = useCallback(async () => {
    const networks = vm?.spec?.template?.spec?.networks
    const interfaces = vm?.spec?.template?.spec?.domain?.devices?.interfaces
    // add pod network back in as it was
    const podNetwork = networks.find((network) => !!network.hasOwnProperty('pod'))
    const podInterface = interfaces.find((iface) => iface.name === podNetwork?.name)
    const interfacesList = [
      podInterface,
      ...params.existingNetworks.map((network) => ({ name: network.interfaceName, bridge: {} })),
      ...params.newNetworks.map((network) => ({ name: network.interfaceName, bridge: {} })),
    ].filter((iface) => !!iface)
    const networksList = [
      podNetwork,
      ...params.existingNetworks.map((network) => ({
        name: network.interfaceName,
        multus: { networkName: network.networkName },
      })),
      ...params.newNetworks.map((network) => ({
        name: network.interfaceName,
        multus: { networkName: network.networkName },
      })),
    ].filter((network) => !!network)
    const body = {
      spec: {
        template: {
          spec: {
            domain: {
              devices: {
                interfaces: interfacesList,
              },
            },
            networks: networksList,
          },
        },
      },
    }
    const { success } = await updateFn({
      ...vm,
      body,
      requestType: 'patch',
    })
    if (success) {
      handleClose()
    }
  }, [params, vm, handleClose])

  return (
    <ModalForm
      open={open}
      title={`Edit Networks`}
      onSubmit={submitForm}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle={`Save Changes`}
      maxWidth={528}
    >
      <div className={classes.root}>
        <FormFieldSection title="Settings">
          {params?.existingNetworks?.length ? (
            params?.existingNetworks?.map((network, idx) => (
              <div key={idx}>
                <Text className={classes.interfaceLabel} variant="caption1">
                  {network.interfaceName}
                </Text>
                <div className={classes.networkField}>
                  <PicklistField
                    DropdownComponent={NetworkPicklist}
                    id={`existingNetworks.${idx}.networkName`}
                    onChange={(value) => {
                      const updatedNetwork = {
                        interfaceName: network.interfaceName,
                        networkName: value,
                      }
                      const existingNetworks = params.existingNetworks.map((nw) => {
                        if (nw.interfaceName === network.interfaceName) {
                          return updatedNetwork
                        }
                        return nw
                      })
                      updateParams({ existingNetworks })
                    }}
                    initialValue={network?.networkName}
                    label="Network"
                    clusterId={vm?.clusterId}
                    namespace={vm?.namespace}
                    required
                  />
                  <div
                    className={classes.removeLabel}
                    onClick={() => removeExistingNetwork(network)}
                  >
                    <FontAwesomeIcon className={classes.minusIcon} size="xl" solid>
                      circle-minus
                    </FontAwesomeIcon>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <Text variant="caption1">No Networks</Text>
          )}
        </FormFieldSection>
        <FormFieldSection title="Add New">
          {params?.newNetworks?.map((network, idx) => (
            <div key={idx}>
              <Text className={classes.interfaceLabel} variant="caption1">
                {network.interfaceName}
              </Text>
              <div className={classes.networkField}>
                <PicklistField
                  DropdownComponent={NetworkPicklist}
                  id={`newNetworks.${idx}.networName`}
                  onChange={(value) => {
                    const updatedNetwork = {
                      interfaceName: network.interfaceName,
                      networkName: value,
                    }
                    const newNetworks = params.newNetworks.map((nw) => {
                      if (nw.interfaceName === network.interfaceName) {
                        return updatedNetwork
                      }
                      return nw
                    })
                    updateParams({ newNetworks })
                  }}
                  initialValue={network?.networkName}
                  label="Network"
                  clusterId={vm?.clusterId}
                  namespace={vm?.namespace}
                  required
                />
                <div className={classes.removeLabel} onClick={() => removeNewNetwork(network)}>
                  <FontAwesomeIcon className={classes.minusIcon} size="xl" solid>
                    circle-minus
                  </FontAwesomeIcon>
                </div>
              </div>
            </div>
          ))}
          <div className={classes.addLabel} onClick={addNewNetwork}>
            <FontAwesomeIcon className={classes.plusIcon} size="xl" solid>
              circle-plus
            </FontAwesomeIcon>
            <Text variant="body2">Add Nic</Text>
          </div>
        </FormFieldSection>
      </div>
    </ModalForm>
  )
}
