import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {}

// values match with moment's add/subtract API for use in actions.js
const items = [
  { label: 'Always', value: 'Always' },
  { label: 'RerunOnFailure', value: 'RerunOnFailure' },
  { label: 'Manual', value: 'Manual' },
  { label: 'Halted', value: 'Halted' },
]

export default function RunStrategyPicklist({
  name = 'runStrategy',
  label = 'Run Strategy',
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown name={name} label={label} items={items} {...rest} />
}
