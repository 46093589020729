import React, { useCallback } from 'react'
import Text from 'core/elements/Text'
import { powerVirtualMachineInstance } from './new-actions'
import Button from 'core/elements/button'
import { capitalizeString } from 'utils/misc'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Modal from 'core/elements/modal'

const VmiPowerOperationDialog = ({ rows: [virtualMachineInstance], params, onClose }) => {
  const { update: powerVmi, updating: poweringVmi } = useUpdateAction(powerVirtualMachineInstance)

  const title = `${capitalizeString(params?.operation)} VMI "${virtualMachineInstance?.name}"?`
  const handleAction = useCallback(async () => {
    const vmi = virtualMachineInstance?.vmi ? virtualMachineInstance?.vmi : virtualMachineInstance
    await powerVmi({
      ...vmi,
      operation: params?.operation,
    })
    onClose(true)
  }, [virtualMachineInstance])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={title}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={poweringVmi}>
            Cancel
          </Button>
          <Button onClick={handleAction} loading={poweringVmi}>
            {capitalizeString(params?.operation)} VMI
          </Button>
        </>
      }
    >
      <Text variant="body2">
        Please confirm you want to {params?.operation} this Virtual Machine Instance.
      </Text>
    </Modal>
  )
}

export default VmiPowerOperationDialog
