import React from 'react'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import FormReviewTable from 'core/components/validatedForm/review-table'
import { findClusterName } from 'k8s/util/helpers'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { useSelector } from 'react-redux'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import Progress from 'core/components/progress/Progress'
import jsYaml from 'js-yaml'
import Accordion from 'core/components/accordion/Accordion'

const RenderClusterName = ({ clusterId }) => {
  const { loading } = useListAction(listClusters)
  const clusters = useSelector(clustersSelector)
  const clusterName = findClusterName(clusters, clusterId)
  return (
    <Progress inline loading={loading} overlay={false} message={null}>
      <span>{clusterName}</span>
    </Progress>
  )
}

const RenderVolumes = ({ volumes }) => {
  return (
    <>
      {volumes.map((volume) => (
        <div key={volume.name}>{volume.name}</div>
      ))}
    </>
  )
}

const RenderNetworks = ({ networks }) => {
  return (
    <>
      {networks.map((network) => (
        <div key={network.name}>{network.name}</div>
      ))}
    </>
  )
}

const basicColumns = [
  {
    id: 'metadata.name',
    label: 'VM Name',
  },
  { id: 'clusterId', label: 'Cluster', render: (value) => <RenderClusterName clusterId={value} /> },
  { id: 'namespace', label: 'Namespace' },
  {
    id: 'spec.runStrategy',
    label: 'Run Strategy',
  },
]

const storageColumns = [
  {
    id: 'spec.template.spec.volumes',
    label: 'Volumes',
    renderArray: true,
    render: (value) => <RenderVolumes volumes={value} />,
  },
]

const resourceColumns = [
  {
    id: 'spec.instancetype.name',
    label: 'Instance Type',
    hide: (data) => !data?.spec?.instancetype?.name,
  },
  {
    id: 'spec.template.spec.domain.cpu.cores',
    label: 'VCPUs',
    hide: (data) => !data?.spec?.template?.spec?.domain?.cpu?.cores,
  },
  {
    id: 'spec.template.spec.domain.memory.guest',
    label: 'RAM',
    hide: (data) => !data?.spec?.template?.spec?.domain?.memory?.guest,
  },
]

const networkColumns = [
  {
    id: 'spec.template.spec.networks',
    label: 'Networks',
    renderArray: true,
    render: (value) => <RenderNetworks networks={value} />,
  },
]

export default function YamlReviewStep({ wizardContext }) {
  const yamlJson = jsYaml.load(wizardContext.yaml)
  const combinedJson = {
    ...wizardContext,
    ...yamlJson,
  }

  return (
    <FormFieldSection title="Finalize & Review">
      <Accordion id="basic" title="Basic Settings">
        <FormReviewTable data={combinedJson} columns={basicColumns} />
      </Accordion>
      <Accordion id="storage" title="Storage">
        <FormReviewTable data={combinedJson} columns={storageColumns} />
      </Accordion>
      <Accordion id="resource" title="Resource">
        <FormReviewTable data={combinedJson} columns={resourceColumns} />
      </Accordion>
      <Accordion id="network" title="Network">
        <FormReviewTable data={combinedJson} columns={networkColumns} />
      </Accordion>
    </FormFieldSection>
  )
}
