import React from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import MethodCard from './MethodCard'

const useStyles = makeStyles<Theme>((theme) => ({
  radioText: {
    textTransform: 'uppercase',
    color: theme.components.typography.passive,
  },
  radioCards: {
    display: 'grid',
    gap: 16,
  },
}))

export const NetworkStep = ({ wizardContext, setWizardContext, onNext, handleNext }) => {
  const classes = useStyles({})

  return (
    <ValidatedForm
      onSubmit={setWizardContext}
      initialValues={wizardContext}
      triggerSubmit={onNext}
      elevated={false}
    >
      <Text variant="subtitle2">Select method of creation</Text>
      <Text variant="body1">
        To start configuration choose the method of virtual machine creation.
      </Text>
      <div className={classes.radioCards}>
        <Text variant="caption2" className={classes.radioText}>
          Select one of the below:
        </Text>
        <MethodCard
          label="Upload a YAML file"
          icon="upload"
          onClick={() => {
            setWizardContext({ method: 'yaml' })
            handleNext()
          }}
        />
        <MethodCard
          label="Create using wizard"
          icon="wizard"
          onClick={() => {
            setWizardContext({ method: 'wizard' })
            handleNext()
          }}
        />
      </div>
    </ValidatedForm>
  )
}

export default NetworkStep
