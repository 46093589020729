import React, { useCallback, useState, useMemo } from 'react'
import ModalForm from 'core/elements/modal/ModalForm'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ClusterPicklistField from 'app/plugins/infrastructure/components/clusters/form-components/ClusterPicklistField'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import useUpdateAction from 'core/hooks/useUpdateAction'
import {
  createClusterAddon,
  listClusterAddons,
} from 'app/plugins/infrastructure/components/clusters/cluster-addons/new-actions'
import { compareVersions } from 'k8s/util/helpers'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { clusterAddonsSelector } from 'app/plugins/infrastructure/components/clusters/cluster-addons/selectors'
import { ClusterAddonType } from 'app/plugins/infrastructure/components/clusters/cluster-addons/model'
import { enableKubevirt } from './virtual-machines/new-actions'
import CheckboxField from 'core/components/validatedForm/CheckboxField'

const bareOsClustersOnly = (clusters) =>
  clusters.filter(
    (cluster) =>
      cluster.cloudProviderType === CloudProviders.BareOS &&
      compareVersions(cluster.kubeRoleVersion, '1.21') >= 0,
  )

const initialValues = {
  enableLuigi: true,
  enableKubevirt: true,
}

export default function InstallKubevirtForm({ open, onClose }) {
  const {
    update: enableAddon,
    updating: enablingAddon,
    error: errorEnablingAddon,
    reset,
  } = useUpdateAction(createClusterAddon)
  const [clusterId, setClusterId] = useState(null)
  const { loading, message } = useListAction(listClusterAddons, {
    params: { clusterId },
  })
  const enabledClusterAddons = useSelectorWithParams(clusterAddonsSelector, { clusterId })
  const luigiOperatorEnabled = useMemo(
    () => !!enabledClusterAddons.find((addon) => addon.type === ClusterAddonType.Luigi),
    [enabledClusterAddons],
  )

  const handleSubmit = useCallback(async (values) => {
    const success = await enableKubevirt(values?.clusterId, luigiOperatorEnabled, enableAddon)
    if (success) {
      reset()
      onClose()
    }
  }, [])

  const handleClusterChange = useCallback(
    (setFieldValue) => (value) => {
      setFieldValue('clusterId')(value)
      setClusterId(value)
      reset()
    },
    [],
  )

  return (
    <ModalForm
      open={open}
      title="Enable Kubevirt on a cluster"
      onClose={onClose}
      onSubmit={handleSubmit}
      submitTitle="Enable"
      submitting={enablingAddon}
      error={errorEnablingAddon}
      initialValues={initialValues}
      loading={loading}
      loadingMessage={message}
    >
      {/* The state from ValidatedFrom is passed up here to this fn */}
      {({ setFieldValue, values }) => (
        <>
          <FormFieldSection title="Select a Cluster" step={1}>
            <ClusterPicklistField
              onChange={handleClusterChange(setFieldValue)}
              filterFn={bareOsClustersOnly}
            />
          </FormFieldSection>
          <FormFieldSection title="Configure Settings" step={2}>
            <CheckboxField
              id="enableLuigi"
              value={values.useDefaultValues}
              label="Enable Luigi Operator"
              onChange={setFieldValue('enableLuigi')}
              disabled
            />
            <CheckboxField
              id="enableKubevirt"
              value={values.enableKubevirt}
              label="Enable Kubevirt"
              onChange={setFieldValue('enableKubevirt')}
              disabled
            />
          </FormFieldSection>
        </>
      )}
    </ModalForm>
  )
}
