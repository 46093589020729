import React, { useCallback, useEffect, useRef } from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'core/elements/Text'
import ListTableField from 'core/components/validatedForm/ListTableField'
import PodNetworkBindingPicklist from './PodNetworkBindingPicklist'
import { listNetworks } from 'k8s/components/networking/network/actions'
import { networksSelector } from 'k8s/components/networking/network/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import PicklistField from 'core/components/validatedForm/DropdownField'
import { getNetworkJson } from './helpers'

const useStyles = makeStyles<Theme>((theme) => ({
  bindingModeField: {
    marginBottom: theme.spacing(3),
  },
}))

const renderBindingMode = (_) => <>bridge</>

const columns = [
  { id: 'name', label: 'Network' },
  { id: 'kind', label: 'Binding Mode', render: renderBindingMode },
]

export const NetworkStep = ({ wizardContext, setWizardContext, onNext }) => {
  const classes = useStyles({})

  const { loading, reload: reloadNetworks } = useListAction(listNetworks, {
    params: {
      clusterId: wizardContext.clusterId,
      namespace: wizardContext.namespace,
    },
    requiredParams: ['clusterId', 'namespace'],
  })
  const networks = useSelectorWithParams(networksSelector, {
    clusterId: wizardContext.clusterId,
    namespace: wizardContext.namespace,
  })

  // Only run the processing of disks for yaml template upon successful
  // submission of step -- to keep this up-to-date constantly is too much processing
  const validatorRef = useRef(null)
  const setupValidator = (validate) => {
    validatorRef.current = { validate }
  }

  const submitStep = useCallback(async () => {
    const isValid = await validatorRef.current.validate()
    if (!isValid) {
      return false
    }
    const { interfaceSpec, networkSpec } = getNetworkJson(wizardContext)
    setWizardContext({
      interfacesObj: interfaceSpec,
      networksObj: networkSpec,
    })
    return true
  }, [wizardContext?.podNetworkBindingType, wizardContext?.selectedNetworks])

  useEffect(() => {
    onNext(submitStep)
  }, [submitStep])

  return (
    <ValidatedForm
      // onSubmit={setWizardContext}
      initialValues={wizardContext}
      triggerSubmit={setupValidator}
      elevated={false}
    >
      <FormFieldSection title="Network Selection">
        <div className={classes.bindingModeField}>
          <PicklistField
            DropdownComponent={PodNetworkBindingPicklist}
            id="podNetworkBindingType"
            onChange={(value) => setWizardContext({ podNetworkBindingType: value })}
            value={wizardContext.podNetworkBindingType}
            label="Select Binding Mode for Pod Network"
            tooltip="Live migration is not allowed with a pod network binding of bridge interface type"
            required
          />
        </div>
        <Text variant="caption1">Select Additional Networks</Text>
        <ListTableField
          id="selectedNetworks"
          data={networks}
          loading={loading}
          columns={columns}
          onChange={(value) => setWizardContext({ selectedNetworks: value })}
          value={wizardContext.selectedNetworks}
          onReload={() => {
            reloadNetworks(true, true)
          }}
          multiSelection
        />
      </FormFieldSection>
    </ValidatedForm>
  )
}

export default NetworkStep
