import React, { useEffect, useState } from 'react'
import DocumentMeta from 'core/components/DocumentMeta'
import Progress from 'core/components/progress/Progress'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import { useSelector } from 'react-redux'
import InformationalLandingPage from '../InformationalLandingPage'
import { makeParamsAllClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import VmOverviewPage from './VmOverviewPage'

const isFalse = (x) => x === false
const allClustersParams = {
  orderBy: 'name',
  kubevirtClusters: true,
}
const defaultParamsAllClusterSelector = makeParamsAllClustersSelector(allClustersParams)
const VirtualMachinesPage = () => {
  const [loadingState, setLoadingState] = useState({ hasLoaded: false, loading: true })

  const { loading: clustersLoading } = useListAction(listClusters)
  const { loading: importedClustersLoading } = useListAction(listImportedClusters)

  const allClusters = useSelector(defaultParamsAllClusterSelector)

  const doneLoading = isFalse(clustersLoading) && isFalse(importedClustersLoading)

  useEffect(() => {
    if (!doneLoading && !loadingState.hasLoaded && loadingState.loading) {
      setLoadingState({ hasLoaded: true, loading: true })
    }
    if (doneLoading && loadingState.loading) {
      setLoadingState({ hasLoaded: true, loading: false })
    }
  }, [doneLoading, loadingState])

  if (loadingState.loading) {
    return <Progress loading message="Loading Clusters with KubeVirt..." />
  }

  return (
    <>
      <DocumentMeta title="Virtual Machines" />
      {!allClusters.length ? <InformationalLandingPage /> : <VmOverviewPage />}
    </>
  )
}

export default VirtualMachinesPage
