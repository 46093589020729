import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import { trackEvent } from 'utils/tracking'
import { routes } from 'core/utils/routes'
import Bugsnag from 'utils/bugsnag'
import { deleteInstanceType, deleteClusterInstanceType } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Modal from 'core/elements/modal'

export default function DeleteInstanceTypeDialog({ rows: [instanceType], onClose }) {
  const { update: deleteInstanceTypeFn, updating: deletingInstanceType } = useUpdateAction(
    deleteInstanceType,
  )
  const {
    update: deleteClusterInstanceTypeFn,
    updating: deletingClusterInstanceType,
  } = useUpdateAction(deleteClusterInstanceType)

  const deleteFunctionMap = {
    VirtualMachineInstancetype: deleteInstanceTypeFn,
    VirtualMachineClusterInstancetype: deleteClusterInstanceTypeFn,
  }

  const title = `Permanently delete ${instanceType.kind} "${instanceType?.name}"?`
  const handleDelete = useCallback(async () => {
    Bugsnag.leaveBreadcrumb(`Attempting to delete ${instanceType.kind}`, {
      cluster_uuid: instanceType?.clusterId,
      name: instanceType?.name,
      namespace: instanceType?.namespace,
    })
    const deleteFn = deleteFunctionMap[instanceType?.kind]
    await deleteFn(instanceType)
    trackEvent('Delete instance type', {
      cluster_uuid: instanceType?.clusterId,
      name: instanceType?.name,
      namespace: instanceType?.namespace,
    })
    onClose(true)
  }, [instanceType])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={title}
      maxWidth={600}
      footer={
        <>
          <Button
            variant="secondary"
            onClick={onClose}
            disabled={deletingInstanceType || deletingClusterInstanceType}
          >
            Don't Delete
          </Button>
          <Button
            onClick={handleDelete}
            loading={deletingInstanceType || deletingClusterInstanceType}
          >
            Delete Instance Type
          </Button>
        </>
      }
    >
      <Text variant="body2">This action cannot be undone, are you sure you want to do this?</Text>
    </Modal>
  )
}
