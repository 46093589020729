import React, { useMemo } from 'react'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import LiveMigrationsOverviewHeader from './LiveMigrationsOverviewHeader'
import useListAction from 'core/hooks/useListAction'
import { listLiveMigrations, listVirtualMachineInstances } from '../new-actions'
import { liveMigrationsSelector } from '../selectors'
import InferActionParams from 'core/actions/InferActionParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'
import DateCell from 'core/components/listTable/cells/DateCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { ArrayElement } from 'core/actions/Action'
import Grid from 'core/elements/grid'
import clsx from 'clsx'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip'
import { topMiddle } from 'core/elements/menu/defaults'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import ListContainer from 'core/containers/ListContainer'
import { pick, update } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import DocumentMeta from 'core/components/DocumentMeta'
import PollingData from 'core/components/PollingData'

type ModelDataKey = DataKeys.LiveMigrations
type SelectorModel = ArrayElement<ReturnType<typeof liveMigrationsSelector>>
type ActionParams = InferActionParams<typeof listLiveMigrations>

type Params = ActionParams & {
  clusterId?: string
  namespace?: string
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  namespace: null,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'LiveMigrations',
  listTablePrefs,
)
// maybe change these
const searchTargets = [
  'status.phase',
  'spec.vmiName',
  'vmi.status.migrationState.sourceNode',
  'vmi.status.migrationState.targetNode',
]

const migrationMap = {
  Pending: {
    category: 'pending',
    step: 0,
    color: 'warning',
  },
  Scheduling: {
    category: 'active',
    step: 1,
    color: 'warning',
  },
  Scheduled: {
    category: 'active',
    step: 2,
    color: 'warning',
  },
  PreparingTarget: {
    category: 'active',
    step: 3,
    color: 'warning',
  },
  TargetReady: {
    category: 'active',
    step: 4,
    color: 'warning',
  },
  Running: {
    category: 'active',
    step: 5,
    color: 'warning',
  },
  Succeeded: {
    category: 'past',
    color: 'success',
  },
  Failed: {
    category: 'past',
    color: 'error',
  },
}

const MigrationStatus = ({ status }) => {
  const classes = useStyles()
  return (
    <div className={classes.status}>
      <FontAwesomeIcon className={classes[migrationMap[status]?.color]} size="xs" solid>
        circle
      </FontAwesomeIcon>
      <Text variant="caption1">{status}</Text>
      {migrationMap[status]?.step && <Text variant="body2">({migrationMap[status]?.step}/5)</Text>}
    </div>
  )
}

const currentMigrationColumns: GridViewColumn<SelectorModel>[] = [
  { key: 'status.phase', label: 'Status', render: (value) => <MigrationStatus status={value} /> },
  {
    key: 'spec.vmiName',
    label: 'VM Name',
    render: (value, entity) => (
      <Tooltip
        message={
          <>
            <div>Cluster: {entity.clusterName}</div>
            <div>Namespace: {entity.namespace}</div>
          </>
        }
        align={topMiddle.align}
        offset={topMiddle.offset}
      >
        <Text variant="body2">{value}</Text>
      </Tooltip>
    ),
  },
  {
    key: 'vmi.status.migrationState.targetNode',
    label: 'Target',
    formatFn: (value) => (value ? value : 'N/A'),
  } as GridViewColumn<SelectorModel, 'vmi.status.migrationState.targetNode'>,
  {
    key: 'created',
    label: 'Created',
    render: (value) => <DateCell value={value} />,
  } as GridViewColumn<SelectorModel, 'created'>,
]

const pastMigrationColumns: GridViewColumn<SelectorModel>[] = [
  { key: 'status.phase', label: 'Status', render: (value) => <MigrationStatus status={value} /> },
  {
    key: 'spec.vmiName',
    label: 'VM Name',
    render: (value, entity) => (
      <Tooltip
        message={
          <>
            <div>Cluster: {entity.clusterName}</div>
            <div>Namespace: {entity.namespace}</div>
          </>
        }
        align={topMiddle.align}
        offset={topMiddle.offset}
      >
        <Text variant="body2">{value}</Text>
      </Tooltip>
    ),
  },
  {
    key: 'vmi.status.migrationState.sourceNode',
    label: 'From',
    formatFn: (value, entity) => (value ? value : entity?.vmi?.status?.nodeName),
  } as GridViewColumn<SelectorModel, 'vmi.status.migrationState.sourceNode'>,
  {
    key: 'vmi.status.migrationState.targetNode',
    label: 'Target',
    formatFn: (value) => (value ? value : 'N/A'),
  } as GridViewColumn<SelectorModel, 'vmi.status.migrationState.targetNode'>,
  {
    key: 'lastUpdated',
    label: 'Migration Time',
    render: (value) => <DateCell value={value} />,
  } as GridViewColumn<SelectorModel, 'lastUpdated'>,
]

export default function LiveMigrationsOverview() {
  const classes = useStyles()
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listLiveMigrations, {
    params,
    requiredParams,
  })
  const data = useAppSelector(liveMigrationsSelector)
  // Need to reload VMIs too bc VMI data holds info on source/target nodes
  const { loading: loadingVmis, reload: reloadVmis } = useListAction(listVirtualMachineInstances, {
    params,
    requiredParams,
  })

  const categorizedMigrations = useMemo(
    () =>
      data.reduce(
        (accum, migration) => {
          const collection = migrationMap[migration?.status?.phase]?.category
          if (collection && accum[collection]) {
            if (collection === 'past') {
              // Should only include the latest migration object for a particular VMI
              const olderExistsIdx = accum[collection].findIndex((existingItem) => {
                return (
                  existingItem?.vmi?.name === migration?.vmi?.name &&
                  existingItem?.vmi?.clusterId === migration?.vmi?.clusterId &&
                  existingItem?.lastUpdated < migration?.lastUpdated
                )
              })
              if (olderExistsIdx > -1) {
                return {
                  ...accum,
                  [collection]: update(olderExistsIdx, migration, accum[collection]),
                }
              }
            }
            return {
              ...accum,
              [collection]: [...accum[collection], migration],
            }
          }
          return accum
        },
        {
          active: [],
          pending: [],
          past: [],
        },
      ),
    [data],
  )

  return (
    <div>
      <DocumentMeta title="Live Migrations" />
      {/* if this page is not performant due to the reloads of vms/vmis
      associated with live migrations, reduce the polling interval */}
      <PollingData loading={loading} onReload={reload} refreshDuration={1000 * 15} hidden />
      <LiveMigrationsOverviewHeader liveMigrations={categorizedMigrations} />
      <div className={classes.migrationTables}>
        <div className={clsx(classes.activeMigrations, classes.sharedRow)}>
          <Grid
            label="Active Migrations"
            data={categorizedMigrations.active}
            loading={loading || loadingVmis}
            columns={currentMigrationColumns}
            uniqueIdentifier="id"
            onRefresh={() => {
              reload(true, true)
              reloadVmis(true, true)
            }}
            rowsPerPage={5}
            disableColumnHiding
          />
        </div>
        <div className={clsx(classes.pendingMigrations, classes.sharedRow)}>
          <Grid
            label="Pending Migrations"
            data={categorizedMigrations.pending}
            loading={loading || loadingVmis}
            columns={currentMigrationColumns}
            uniqueIdentifier="id"
            onRefresh={() => {
              reload(true, true)
              reloadVmis(true, true)
            }}
            rowsPerPage={5}
            disableColumnHiding
          />
        </div>
        <div className={classes.pastMigrations}>
          {
            // TODO: Change this title of the grid view to say Completed migrations
          }
          <ListContainer<ModelDataKey, SelectorModel>
            dataKey={DataKeys.LiveMigrations}
            searchTargets={searchTargets}
            uniqueIdentifier="id"
            loading={loading || loadingVmis}
            loadingMessage={message}
            onRefresh={() => {
              reload(true, true)
              reloadVmis(true, true)
            }}
            data={categorizedMigrations.past}
            columns={pastMigrationColumns}
            getParamsUpdater={getParamsUpdater}
            label="Completed Migrations"
            disableColumnHiding
            {...pick(listTablePrefs, params)}
          />
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  migrationTables: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
      "active-migrations pending-migrations"
      "past-migrations past-migrations"
    `,
    gap: '16px 8px',
  },
  // This is just in case one is larger than the other, fill out the space with similar color
  sharedRow: {
    background: theme.components.table.background,
    border: `1px solid ${theme.components.table.border}`,
    borderTop: 'none',
    borderRadius: 4,
  },
  activeMigrations: {
    gridArea: 'active-migrations',
  },
  pendingMigrations: {
    gridArea: 'pending-migrations',
  },
  pastMigrations: {
    gridArea: 'past-migrations',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  warning: {
    color: theme.components.badge.warning.color,
    position: 'relative',
    top: 1,
  },
  success: {
    color: theme.components.badge.success.color,
    position: 'relative',
    top: 1,
  },
  error: {
    color: theme.components.badge.error.color,
    position: 'relative',
    top: 1,
  },
}))
