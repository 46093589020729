import React from 'react'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { allInstanceTypesSelector } from './selectors'
import { listInstanceTypes, listClusterInstanceTypes } from './actions'
import InferActionParams from 'core/actions/InferActionParams'
import DocumentMeta from 'core/components/DocumentMeta'
import ListContainer from 'core/containers/ListContainer'
import { routes } from 'core/utils/routes'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useListAction from 'core/hooks/useListAction'
import { useAppSelector } from 'app/store'
import { GridViewColumn } from 'core/elements/grid/Grid'
import DeleteInstanceTypeDialog from './DeleteInstanceTypeDialog'
import EditInstanceTypeDialog from './EditInstanceTypeDialog'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'

type ModelDataKey = DataKeys.InstanceTypes | DataKeys.ClusterInstanceTypes
type SelectorModel = ArrayElement<ReturnType<typeof allInstanceTypesSelector>>
type ActionParams =
  | InferActionParams<typeof listInstanceTypes>
  | InferActionParams<typeof listClusterInstanceTypes>

type Params = ActionParams & {
  clusterId?: string
  namespace?: string
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  namespace: null,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'InstanceTypes',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName', 'namespace']

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'spec.cpu.guest',
    label: 'vCPUs',
  },
  {
    key: 'spec.memory.guest',
    label: 'Memory',
  },
  // kubevirt is bugged, reenable when it works again
  // {
  //   key: 'spec.cpu.dedicatedCPUPlacement',
  //   label: 'Dedicated CPU Placement',
  //   formatFn: (val: boolean) => (val ? 'Enabled' : 'Disabled'),
  // },
  // {
  //   key: 'spec.memory.hugepages.pageSize',
  //   label: 'Huge Pages',
  //   formatFn: (val: string) => (val ? val : 'N/A'),
  // },
  {
    key: 'namespace',
    label: 'Scope',
    formatFn: (val: string) => (val ? `Namespace: ${val}` : 'Cluster Scoped'),
  },
  {
    key: 'clusterName',
    label: 'Cluster',
  },
]

export const batchActions = [
  {
    label: 'Edit',
    icon: 'edit',
    BatchActionButton: getGridDialogButton(EditInstanceTypeDialog),
  },
  {
    label: 'Delete',
    icon: 'trash-alt',
    BatchActionButton: getGridDialogButton(DeleteInstanceTypeDialog),
  },
]

export default function InstanceTypesListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const {
    message: instanceTypeMessage,
    loading: loadingInstanceTypes,
    reload: reloadInstanceTypes,
  } = useListAction(listInstanceTypes, {
    params,
    requiredParams,
  })
  const {
    message: clusterInstanceTypeMessage,
    loading: loadingClusterInstanceTypes,
    reload: reloadClusterInstanceTypes,
  } = useListAction(listClusterInstanceTypes, {
    params,
    requiredParams,
  })
  const data = useAppSelector(allInstanceTypesSelector)

  return (
    <>
      <DocumentMeta title="Instance Types" />
      <ListContainer<ModelDataKey, SelectorModel>
        label="Instance Types"
        dataKey={DataKeys.InstanceTypes}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loadingInstanceTypes || loadingClusterInstanceTypes}
        loadingMessage={instanceTypeMessage || clusterInstanceTypeMessage}
        onRefresh={() => {
          reloadInstanceTypes(true, true)
          reloadClusterInstanceTypes(true, true)
        }}
        data={data}
        columns={columns}
        addUrl={routes.instanceTypes.add.path()}
        addText="Add Instance Type"
        getParamsUpdater={getParamsUpdater}
        batchActions={batchActions}
        multiSelection={false}
        tooltip={
          'Instance types provide a way to define a set of resources, performance, and other runtime characteristics, allowing users to reuse these definitions across multiple VMs during VM creation.'
        }
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
