import React, { useCallback, useEffect, useRef } from 'react'
import useReactRouter from 'use-react-router'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import DropdownField from 'core/components/validatedForm/DropdownField'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import { customValidator, maxLengthValidator, requiredValidator } from 'core/utils/fieldValidators'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import RunStrategyPicklist from './RunStrategyPicklist'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { remove, update } from 'ramda'

const useStyles = makeStyles<Theme>((theme) => ({
  fullWidth: {
    width: '100% !important',
    maxWidth: '60vw',
  },
  minus: {
    fontWeight: 900,
    color: theme.palette.blue.main,
    justifySelf: 'end',
    position: 'relative',
    top: 14,
  },
  addNewEntry: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, max-content)',
    marginTop: 16,
    alignItems: 'center',
    gap: 8,
  },
  plus: {
    color: theme.palette.primary.main,
    fontWeight: 900,
  },
  clickable: {
    cursor: 'pointer',
  },
  extraNameRow: {
    maxWidth: 400,
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr max-content',
    gap: 32,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    margin: theme.spacing(3, 0),
  },
}))

const nameValidations = [
  requiredValidator,
  maxLengthValidator(32),
  customValidator((name) => {
    return /^[a-z0-9][a-z0-9-.]+[a-z0-9]$/.test(name)
  }, `Name must consist of lower case alphanumeric characters, '-' or '.' and must start and end with an alphanumeric character`),
]

// Todo: more specific validations for each field
export const BasicStep = ({ wizardContext, setWizardContext, onNext, initialContext }) => {
  const { history } = useReactRouter()
  const classes = useStyles({})
  const validatorRef = useRef(null)
  const setupValidator = (validate) => {
    validatorRef.current = { validate }
  }

  // Preserve all the data on this step, but reset all the future steps
  const resetSteps = useCallback(
    (cbWizardContext) => {
      setWizardContext({
        ...initialContext,
        useYaml: cbWizardContext.useYaml,
        name: cbWizardContext.name,
        names: cbWizardContext.names,
        numVms: cbWizardContext.numVms,
        vmNames: cbWizardContext.vmNames,
        vmType: cbWizardContext.vmType,
        clusterId: cbWizardContext.clusterId,
        namespace: cbWizardContext.namespace,
        yaml: cbWizardContext.yaml,
        runStrategy: cbWizardContext.runStrategy,
      })
    },
    [setWizardContext, initialContext],
  )

  const submitStep = useCallback(async () => {
    const isValid = await validatorRef.current.validate()
    return isValid
  }, [wizardContext])

  useEffect(() => {
    onNext(submitStep)
  }, [submitStep])

  return (
    <ValidatedForm
      onSubmit={setWizardContext}
      initialValues={wizardContext}
      triggerSubmit={setupValidator}
      classes={{ root: classes.validatedFormContainer }}
      fullWidth
      elevated={false}
    >
      <>
        <FormFieldSection title="Basic Settings">
          <TextField
            id="name"
            label="VM Name"
            onChange={(value) => setWizardContext({ name: value })}
            value={wizardContext.name}
            info="Choose a name for your VM"
            validations={nameValidations}
          />
          {wizardContext?.names?.map((name, index) => (
            <div className={classes.extraNameRow}>
              <TextField
                id={`names.${index}`}
                label="VM Name"
                onChange={(value) =>
                  setWizardContext({ names: update(index, value, wizardContext?.names) })
                }
                value={wizardContext?.names?.[index]}
                validations={nameValidations}
              />
              <FontAwesomeIcon
                className={classes.minus}
                onClick={() => {
                  setWizardContext({ names: remove(index, 1, wizardContext?.names) })
                }}
                size="xl"
              >
                minus-circle
              </FontAwesomeIcon>
            </div>
          ))}
          <div className={classes.addNewEntry}>
            <FontAwesomeIcon
              className={classes.plus}
              onClick={() =>
                setWizardContext({
                  names: [
                    ...wizardContext.names,
                    `${wizardContext?.name || 'vm'}-${wizardContext?.names?.length + 2}`,
                  ],
                })
              }
              size="xl"
            >
              plus-circle
            </FontAwesomeIcon>
            <Text
              variant="body2"
              className={classes.clickable}
              onClick={() =>
                setWizardContext({
                  names: [
                    ...wizardContext.names,
                    `${wizardContext?.name}-${wizardContext?.names?.length + 2}`,
                  ],
                })
              }
            >
              Add VM
            </Text>
          </div>
          <hr className={classes.divider} />
          <DropdownField
            DropdownComponent={ClusterPicklist}
            id="clusterId"
            label="Cluster"
            tooltip="The cluster to deploy this resource on"
            onChange={(value) => setWizardContext({ clusterId: value }, resetSteps)}
            value={wizardContext.clusterId}
            onlyKubevirtClusters
            setInitialCluster
            compact={false}
            showAll={false}
            required
          />
          <DropdownField
            DropdownComponent={NamespacePicklist}
            disabled={!wizardContext.clusterId}
            id="namespace"
            label="Namespace"
            tooltip="The namespace to deploy this resource on"
            clusterId={wizardContext.clusterId}
            onChange={(value) => setWizardContext({ namespace: value }, resetSteps)}
            value={wizardContext.namespace}
            selectFirst={false}
            setInitialNamespace
            compact={false}
            showAll={false}
            required
          />
          <DropdownField
            DropdownComponent={RunStrategyPicklist}
            id="runStrategy"
            label="Run Strategy"
            tooltip="The run strategy for this VM"
            onChange={(value) => setWizardContext({ runStrategy: value })}
            value={wizardContext.runStrategy}
            required
          />
        </FormFieldSection>
      </>
    </ValidatedForm>
  )
}

export default BasicStep
