import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import EntityYamlPage from 'k8s/components/common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import jsYaml from 'js-yaml'
import { pathStrOr } from 'utils/fp'
import { routes } from 'core/utils/routes'
import { isEmpty } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listVirtualMachineInstances, updateVirtualMachineInstance } from '../new-actions'
import { virtualMachineInstancesSelector } from '../selectors'
import {
  IVirtualMachineInstanceDetailsPageTabs,
  IVirtualMachineInstanceSelector,
} from '../vmi-model'
import { getFieldsForCard } from 'core/components/InfoPanel'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import OverviewActions from 'core/elements/overview/OverviewActions'
import VmiPowerOperationDialog from '../VmiPowerOperationDialog'
import Overview from './Overview'
import { useSelector } from 'react-redux'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { listServices } from 'k8s/components/services/new-actions'
import { serviceSelectors } from 'k8s/components/services/selectors'
import MigrateVmiDialog from '../vm-details/MigrateVmiDialog'
import DeleteVmInstanceDialog from '../DeleteVmInstanceDialog'
import MigrationStatus from '../vm-details/MigrationStatus'
// import EditVmiDialog from './EditVmiDialog'
import PollingData from 'core/components/PollingData'

const oneSecond = 1000
const { qbert } = ApiClient.getInstance()

const defaultVmi = {} as IVirtualMachineInstanceSelector

const useStyles = makeStyles<Theme>((theme) => ({
  grid: {
    display: 'grid',
    gap: 32,
  },
}))

const VirtualMachineInstanceDetailsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { name, clusterId } = match.params
  const { loading, reload } = useListAction(listVirtualMachineInstances, {
    params: { clusterId },
  })
  const vmis = useSelectorWithParams(virtualMachineInstancesSelector, {
    clusterId,
    useGlobalParams: false,
  })
  const vmi = useMemo(() => vmis.find((vmi) => vmi.name === name) || defaultVmi, [name, vmis])
  const entities = useMemo(() => {
    const pods =
      vmi?.podIds?.map((id) => ({
        id,
      })) || []
    return [vmi, ...pods].filter((entity) => !!entity)
  }, [vmi])

  const { update, updating, error } = useUpdateAction(updateVirtualMachineInstance)

  const { loading: loadingClusters } = useListAction(listClusters)
  const clusters = useSelector(clustersSelector)

  const { loading: loadingServices } = useListAction(listServices, {
    params: {
      clusterId,
      namespace: 'kubevirt',
    },
    requiredParams: ['clusterId', 'namespace'],
  })
  const services = useSelectorWithParams(serviceSelectors, {
    clusterId,
    namespace: 'kubevirt',
    useGlobalParams: false,
  })

  const cluster = useMemo(() => clusters.find((c) => c?.uuid === vmi?.clusterId), [clusters])
  const service = useMemo(() => services.find((s) => s?.name === 'virtvnc'), [services])

  const reloadVmis = useCallback(() => {
    reload(true, true)
  }, [reload])

  const actions = useMemo(
    () => ({
      leftActions: [
        {
          label: 'Refresh',
          icon: 'sync',
          onClick: reloadVmis,
        },
      ],
      rightActions: [
        {
          label: 'Pause',
          icon: 'pause',
          DialogComponent: VmiPowerOperationDialog,
          refreshFn: reloadVmis,
          hideFn: (vmi) => !!vmi?.paused,
          customDialogProps: {
            params: {
              operation: 'pause',
            },
          },
        },
        {
          label: 'Unpause',
          icon: 'play',
          DialogComponent: VmiPowerOperationDialog,
          refreshFn: reloadVmis,
          hideFn: (vmi) => !vmi?.paused,
          customDialogProps: {
            params: {
              operation: 'unpause',
            },
          },
        },
        {
          label: 'Console',
          icon: 'terminal',
          disabledFn: (vmi) => {
            return loadingClusters || loadingServices || !service
          },
          // Todo: What if theres more than 1 port?
          externalLink: `http://${cluster?.masterIp}:${service?.ports?.[0]?.nodePort}/vnc_lite.html?path=k8s/apis/subresources.kubevirt.io/v1/namespaces/${vmi?.namespace}/virtualmachineinstances/${vmi?.name}/vnc`,
        },
        {
          label: 'Live Migration',
          icon: 'turn-up',
          disabledFn: (vmi) => {
            const condition = vmi?.status?.conditions?.find(
              (cond) => cond.type === 'LiveMigratable',
            )
            return condition?.status === 'False'
          },
          tooltipFn: (vm) => {
            const condition = vmi?.status?.conditions?.find(
              (cond) => cond.type === 'LiveMigratable',
            )
            return condition?.status === 'False' ? condition?.message : ''
          },
          DialogComponent: MigrateVmiDialog,
        },
        // Do not need to support VMI edit, patch vmi request also appears to be forbidden
        // {
        //   label: 'Edit',
        //   icon: 'pen-to-square',
        //   DialogComponent: EditVmiDialog,
        // },
        {
          label: 'Delete VMI',
          icon: 'trash-alt',
          DialogComponent: DeleteVmInstanceDialog,
        },
      ],
    }),
    [reloadVmis, cluster, service, loadingClusters, loadingServices],
  )

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: vmi?.clusterName || clusterId,
        name: vmi?.name || name,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [vmi?.clusterName, vmi?.name, name, clusterId])

  const getVmiYaml = useCallback(async () => {
    if (isEmpty(vmi)) return undefined
    return qbert.getVirtualMachineInstanceByName(vmi.clusterId, vmi.namespace, vmi.name)
  }, [vmi])

  const handleVmiUpdate = useCallback(
    async (yaml) => {
      const body = jsYaml.load(yaml)
      const namespace = pathStrOr('', 'metadata.namespace', body)
      const name = pathStrOr('', 'metadata.name', body)
      await update({
        clusterId,
        namespace,
        name,
        body,
      })
    },
    [vmi],
  )

  const metadataFields = [
    { id: 'name', title: 'Name', required: true },
    { id: 'namespace', title: 'Namespace', required: true },
    { id: 'clusterName', title: 'Cluster', required: true },
  ]

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, vmi)
  }, [vmi])

  return (
    <>
      <DocumentMeta title="Virtual Machine Instance Details" breadcrumbs />
      <PollingData hidden loading={loading} onReload={reload} refreshDuration={oneSecond * 120} />
      <div className={classes.grid}>
        <div>
          <OverviewActions<IVirtualMachineInstanceSelector> actions={actions} entity={vmi} />
        </div>
        <MigrationStatus vmi={vmi} />
        <div>
          <Tabs route={routes.virtualMachineInstances.details}>
            <Tab label="Overview" value={IVirtualMachineInstanceDetailsPageTabs.Overview}>
              <Overview vmi={vmi} loading={loading} />
            </Tab>
            <Tab label="YAML" value={IVirtualMachineInstanceDetailsPageTabs.Yaml}>
              <EntityYamlPage
                entityType="VirtualMachineInstance"
                entityName={vmi?.name}
                getYamlFn={getVmiYaml}
                handleUpdate={handleVmiUpdate}
                error={error}
                loading={loading || updating}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default VirtualMachineInstanceDetailsPage
