import React from 'react'
import Text from 'core/elements/Text'
import { renderLabelsAsBadges } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { IPersistentVolumeClaimsSelector } from 'k8s/components/storage/persistent-volume-claims/model'
import { keys } from 'ramda'
import Tooltip from 'core/elements/tooltip'
import { middleLeft } from 'core/elements/menu/defaults'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { routes } from 'core/utils/routes'
import { IPersistentVolumeClaimDetailsPageTabs } from 'k8s/components/storage/persistent-volume-claims/model'

const DataVolumeStatusCell = ({ value: status }) => {
  return (
    <>
      <Text variant="body2">{status?.phase}</Text>
      {status?.progress && <Text variant="body2">({status?.progress})</Text>}
    </>
  )
}

const DataVolumeSourceCell = ({ value: source, item }) => {
  // learned later that container disks can also be used
  const containerImage = item?.containerImage
  if (!source && !containerImage) {
    return null
  }
  const key = keys(source)?.[0]
  const url = source?.[key]?.url
  return containerImage ? (
    <Text variant="body2">{containerImage}</Text>
  ) : (
    <>
      <Text variant="body2">{key}</Text>
      {url && (
        <Tooltip message={url} align={middleLeft.align}>
          <Text variant="body2">({url})</Text>
        </Tooltip>
      )}
    </>
  )
}

export const getDiskColumns = ({
  storageClassIdsByName,
}: {
  storageClassIdsByName: { [key: string]: string }
}) => [
  {
    key: 'name',
    label: 'Name',
  },
  { key: 'type', label: 'Type' },
  { key: 'pvc.capacity.storage', label: 'Capacity' },
  {
    key: 'pvc.status.accessModes',
    label: 'Access Modes',
    render: renderLabelsAsBadges({ variant: 'default' }),
  },
  { key: 'status', label: 'Status', CellComponent: DataVolumeStatusCell },
  { key: 'spec.source', label: 'Source', CellComponent: DataVolumeSourceCell },
  {
    key: 'pvc.name',
    label: 'PVC',
    CellComponent: createGridLinkCell({
      routeToFn: ({ pvc }) =>
        pvc?.id
          ? routes.kubevirtStorage.persistentVolumeClaims.details.path({
              clusterId: pvc?.clusterId,
              id: pvc?.id,
              tab: IPersistentVolumeClaimDetailsPageTabs.Overview,
            })
          : null,
    }),
  },
  {
    key: 'storageClassName',
    label: 'Storage Class',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterId, storageClassName }) =>
        storageClassName
          ? routes.kubevirtStorage.storageClasses.details.path({
              clusterId,
              id: storageClassIdsByName?.[storageClassName],
            })
          : null,
    }),
  },
]

const getVolumes = (entity) => {
  return (
    (entity?.kind === 'VirtualMachine'
      ? entity?.spec?.template?.spec?.volumes
      : entity?.spec?.volumes) || []
  )
}

export interface IDisk {
  name: string
  type: string
}

export const getDisks = ({ entity, dataVolumes, pvcs }): IDisk[] => {
  if (!entity) {
    return []
  }
  const volumes = getVolumes(entity)
  return volumes.map((volume) => {
    if (volume?.hasOwnProperty('dataVolume')) {
      const dataVolume = dataVolumes.find((dv) => dv?.name === volume?.dataVolume?.name) || {}
      const pvc =
        pvcs.find((pvc) => {
          const owner = pvc?.metadata?.ownerReferences?.find((obj) => {
            return obj.kind === 'DataVolume' && obj.name === volume?.dataVolume?.name
          })
          return !!owner
        }) || ({} as IPersistentVolumeClaimsSelector)
      return {
        name: volume?.dataVolume?.name,
        type: 'dataVolume',
        pvc: pvc,
        ...dataVolume,
        storageClassName: pvc?.storageClassName,
      }
    } else if (volume?.hasOwnProperty('persistentVolumeClaim')) {
      const claimName = volume?.persistentVolumeClaim?.claimName
      const pvc =
        pvcs.find((pvc) => pvc?.name === claimName) || ({} as IPersistentVolumeClaimsSelector)
      return {
        name: claimName || volume?.name,
        type: 'persistentVolumeClaim',
        pvc: pvc,
        storageClassName: pvc?.storageClassName,
      }
    } else if (volume?.hasOwnProperty('cloudInitNoCloud')) {
      return {
        name: volume?.name,
        type: 'cloud-init',
      }
    } else if (volume?.hasOwnProperty('containerDisk')) {
      return {
        name: volume?.name,
        type: 'containerDisk',
        containerImage: volume?.containerDisk?.image,
      }
    } else {
      // https://kubevirt.io/user-guide/virtual_machines/disks_and_volumes/#volumes
      // can take the key name, the obj always looks like { <type>: ..., name: ... }
      const volumeKeys = keys(volume)
      const volumeType = volumeKeys.filter((key) => key !== 'name')
      return {
        name: volume?.name,
        type: volumeType,
      }
    }
  })
}

export const splitKubernetesUnitString = (str) => {
  if (!str) {
    return { number: undefined, unit: 'Gi' }
  }
  if (str.includes('Gi')) {
    return { number: str.replace('Gi', ''), unit: 'Gi' }
  } else if (str.includes('Mi')) {
    return { number: str.replace('Mi', ''), unit: 'Mi' }
  } else if (str.includes('G')) {
    return { number: str.replace('G', ''), unit: 'Gi' }
  } else if (str.includes('M')) {
    return { number: str.replace('M', ''), unit: 'Mi' }
  }
  return { number: str, unit: 'Gi' }
}
