import React, { useCallback } from 'react'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    display: 'inline-block',
    cursor: 'pointer',
  },
}))

export default function CardRefreshButton({ onRefresh }) {
  const classes = useStyles()

  const handleClick = useCallback(() => {
    onRefresh()
  }, [onRefresh])

  return (
    <>
      <div className={classes.icon} onClick={handleClick}>
        <FontAwesomeIcon size="md">sync-alt</FontAwesomeIcon>
      </div>
    </>
  )
}
