import React from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'core/elements/Text'
import { codeMirrorOptions } from 'app/constants'
import { customValidator, requiredValidator, yamlValidator } from 'core/utils/fieldValidators'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import jsYaml from 'js-yaml'
import moize from 'moize'
import TextFileDrop from 'core/elements/grid/TextFileDrop'
import DropdownField from 'core/components/validatedForm/DropdownField'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'

const useStyles = makeStyles<Theme>((theme) => ({
  fullWidth: {
    width: '100% !important',
    maxWidth: '50vw',
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    margin: theme.spacing(3, 0),
  },
}))

const moizedYamlLoad = moize(jsYaml.load, {
  maxSize: 10,
})

const yamlValidations = [
  requiredValidator,
  yamlValidator,
  customValidator((yaml, formFields) => {
    try {
      const body = moizedYamlLoad(yaml)
      return body.kind === 'VirtualMachine'
    } catch (err) {
      return true
    }
  }, 'Resource type must be "VirtualMachine"'),
]

export default function YamlStep({ wizardContext, setWizardContext, onNext }) {
  const classes = useStyles({})

  return (
    <ValidatedForm initialValues={wizardContext} triggerSubmit={onNext} elevated={false}>
      <FormFieldSection title="Basic Settings">
        <DropdownField
          DropdownComponent={ClusterPicklist}
          id="clusterId"
          label="Cluster"
          tooltip="The cluster to deploy this resource on"
          onChange={(value) => setWizardContext({ clusterId: value })}
          value={wizardContext.clusterId}
          setInitialCluster
          onlyKubevirtClusters
          compact={false}
          showAll={false}
          required
        />
        <DropdownField
          DropdownComponent={NamespacePicklist}
          disabled={!wizardContext.clusterId}
          id="namespace"
          label="Namespace"
          tooltip="The namespace to deploy this resource on"
          clusterId={wizardContext.clusterId}
          onChange={(value) => setWizardContext({ namespace: value })}
          value={wizardContext.namespace}
          selectFirst={false}
          setInitialNamespace
          compact={false}
          showAll={false}
          required
        />
      </FormFieldSection>
      <hr className={classes.divider} />
      <FormFieldSection title="Upload YAML">
        <Text>Create a Virtual Machine with a YAML file or follow the steps below.</Text>
        <TextFileDrop
          onChange={(value) => setWizardContext({ yaml: value })}
          fileTypes={['.yaml', '.yml']}
        />
        <CodeMirror
          id="advancedYaml"
          label="YAML Resource"
          validations={yamlValidations}
          onChange={(value) => setWizardContext({ yaml: value })}
          value={wizardContext.yaml}
          options={codeMirrorOptions}
          className={classes.fullWidth}
        />
      </FormFieldSection>
    </ValidatedForm>
  )
}
