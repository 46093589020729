import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import Progress from 'core/components/progress/Progress'
import { routes } from 'core/utils/routes'
import ApiClient from 'api-client/ApiClient'
import EntityYamlPage from 'k8s/components/common/entity/entity-yaml-page'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listVirtualMachines, updateVirtualMachine } from './new-actions'
import { virtualMachinesSelector } from './selectors'
import FormWrapper from 'core/components/FormWrapper'
import useUpdateAction from 'core/hooks/useUpdateAction'

const { qbert } = ApiClient.getInstance()

export default function EditVirtualMachinePage() {
  // const classes = useStyles()
  const { history, match } = useReactRouter()
  const id = match.params.id
  const clusterId = match.params.clusterId

  const { loading } = useListAction(listVirtualMachines, {
    params: {
      clusterId,
    },
    requiredParams: ['clusterId'],
  })
  const virtualMachines = useSelectorWithParams(virtualMachinesSelector, { clusterId })

  const virtualMachine = virtualMachines.find((vm) => vm.id === id)

  const { update, updating } = useUpdateAction(updateVirtualMachine)

  const getVirtualMachineYaml = useCallback(async () => {
    if (!virtualMachine) return ''
    return qbert.getVirtualMachineByName(
      virtualMachine.clusterId,
      virtualMachine.namespace,
      virtualMachine.name,
    )
  }, [virtualMachine])

  const handleVirtualMachineUpdate = async (virtualMachineYaml) => {
    const updatedVirtualMachine = JSON.parse(virtualMachineYaml)
    const clusterId = virtualMachine?.clusterId
    const namespace = virtualMachine?.namespace
    const name = updatedVirtualMachine?.metadata?.name || ''
    const { success } = await update({
      clusterId,
      namespace,
      name,
      body: updatedVirtualMachine,
    })
    if (success) {
      history.push(routes.kubevirt.overview.path())
    }
  }

  return (
    <>
      <DocumentMeta title="Edit Virtual Machine" />
      <FormWrapper
        title={`Edit VM ${virtualMachine?.name}`}
        backUrl={routes.kubevirt.overview.path()}
        loading={loading || updating}
        message={loading ? 'Loading VM...' : 'Updating VM...'}
      >
        <Progress loading={loading}>
          <EntityYamlPage
            entityType="Virtual Machine"
            getYamlFn={getVirtualMachineYaml}
            handleUpdate={handleVirtualMachineUpdate}
          />
        </Progress>
      </FormWrapper>
    </>
  )
}
