import { createSharedSelector } from 'core/utils/selectorHelpers'
import {
  virtualMachinesSelector,
  virtualMachineInstancesSelector,
} from '../virtual-machines/selectors'

const getEntities = (vms, vmis) => {
  // Pods may be too much info, don't include those for now
  // const pods = vmis
  //   ?.map(
  //     (vmi) =>
  //       vmi?.podIds?.map((id) => ({
  //         id,
  //       })) || [],
  //   )
  //   .flat()
  return [...vms, ...vmis].filter((entity) => !!entity)
}

export const kubevirtEventsSelector = createSharedSelector(
  virtualMachinesSelector,
  virtualMachineInstancesSelector,
  (vms, vmis) => {
    return getEntities(vms, vmis)
  },
)
