import { IPodSelector } from 'k8s/components/pods/model'

export enum IVirtualMachineInstanceDetailsPageTabs {
  Overview = 'overview',
  Events = 'events',
  Yaml = 'yaml',
}

export interface GetVirtualMachineInstancesResponse {
  apiVersion: string
  items: IVirtualMachineInstance[]
  kind: string
  metadata: GetVirtualMachineInstanceMetadata
}

export interface IVirtualMachineInstance {
  clusterId: string
  apiVersion: string
  kind: string
  metadata: ItemMetadata
  spec: Spec
  status: Status
}

interface ItemMetadata {
  annotations: Record<string, string>
  creationTimestamp: string
  finalizers: string[]
  generation: number
  name: string
  namespace: string
  resourceVersion: string
  uid: string
  labels?: Record<string, string>
  ownerReferences?: OwnerReference[]
}

interface OwnerReference {
  apiVersion: string
  blockOwnerDeletion: boolean
  controller: boolean
  kind: string
  name: string
  uid: string
}

interface Spec {
  domain: Domain
  networks: Network[]
  terminationGracePeriodSeconds?: number
  volumes: Volume[]
  running?: boolean // This doesn't exist here but typescript complaining bc of joined vm/vmi list table
}

interface Domain {
  cpu: CPU
  devices: Devices
  features: Features
  firmware: Firmware
  machine: Machine
  resources: Resources
}

interface CPU {
  cores: number
  sockets: number
  threads: number
}

interface Devices {
  disks: DiskElement[]
  interfaces: DevicesInterface[]
}

interface DiskElement {
  disk: DiskDisk
  name: string
}

interface DiskDisk {
  bus: string
}

interface EmptyObj {}

interface DevicesInterface {
  bridge?: EmptyObj
  name: string
  masquerade?: EmptyObj
}

interface Features {
  acpi: ACPI
}

interface ACPI {
  enabled: boolean
}

interface Firmware {
  uuid: string
}

interface Machine {
  type: string
}

interface Resources {
  requests: Requests
}

interface Requests {
  cpu: string
  memory: string
}

interface Network {
  name: string
  pod: EmptyObj
}

interface Volume {
  containerDisk?: ContainerDisk
  name: string
  cloudInitNoCloud?: CloudInitNoCloud
}

interface CloudInitNoCloud {
  userDataBase64: string
}

interface ContainerDisk {
  image: string
  imagePullPolicy: string
}

interface Status {
  activePods: Record<string, string>
  guestOSInfo: GuestOS
  phase: string
  qosClass: string
  conditions?: Condition[]
  interfaces?: StatusInterface[]
  launcherContainerImageVersion?: string
  migrationMethod?: string
  migrationState?: MigrationState
  nodeName?: string
  volumeStatus?: VolumeStatus[]
}

export interface MigrationState {
  completed: boolean
  endTimestamp: string
  migrationConfiguration: MigrationConfiguration
  migrationUid: string
  mode: string
  sourceNode: string
  startTimestamp: string
  targetDirectMigrationNodePorts: { [key: string]: number }
  targetNode: string
  targetNodeAddress: string
  targetNodeDomainDetected: boolean
  targetPod: string
}

export interface MigrationConfiguration {
  allowAutoConverge: boolean
  allowPostCopy: boolean
  bandwidthPerMigration: string
  completionTimeoutPerGiB: number
  nodeDrainTaintKey: string
  parallelMigrationsPerCluster: number
  parallelOutboundMigrationsPerNode: number
  progressTimeout: number
  unsafeMigrationOverride: boolean
}

export interface GuestOS {
  id: string
  kernelRelease: string
  kernelVersion: string
  name: string
  prettyName: string
  version: string
  versionId: string
}

interface Condition {
  lastProbeTime: null
  lastTransitionTime: null | string
  status: string
  type: string
  message: string
  reason: string
}

interface StatusInterface {
  ipAddress: string
  ipAddresses: string[]
  mac: string
  name: string
}

interface VolumeStatus {
  name: string
  target: string
}

interface GetVirtualMachineInstanceMetadata {
  continue: string
  resourceVersion: string
}

export interface IVirtualMachineInstanceSelector extends IVirtualMachineInstance {
  id: string
  name: string
  clusterName: string
  namespace: string
  created: string
  owners: OwnerReference[]
  networks: VmiNetwork[]
  podIds: string[]
  podObjects: IPodSelector[]
  vmOwner: OwnerReference
}

export interface VmiNetwork {
  networkName: string
  ipAddress?: string
  ipAddresses?: string[]
  mac?: string
}
