import React, { useCallback, useEffect, useState } from 'react'
import ModalForm from 'core/elements/modal/ModalForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'core/elements/Text'
import PicklistField from 'core/components/validatedForm/DropdownField'
import useParams from 'core/hooks/useParams'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import TextField from 'core/components/validatedForm/TextField'
import VmStorageOptionPicklist from '../create/VmStorageOptionPicklist'
import AccessModePicklist from '../create/AccessModePicklist'
import StorageClassesPicklist from 'k8s/components/storage/StorageClassesPicklist'
import uuid from 'uuid'
import CodeBlock from 'core/components/CodeBlock'
import ListTableField from 'core/components/validatedForm/ListTableField'
import { listPersistentVolumeClaims } from 'k8s/components/storage/persistent-volume-claims/new-actions'
import { persistentVolumeClaimsSelector } from 'k8s/components/storage/persistent-volume-claims/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listDataVolumes } from 'k8s/components/storage/data-volumes/new-actions'
import { dataVolumesSelector } from 'k8s/components/storage/data-volumes/selectors'
import { requiredValidator, minValueValidator } from 'core/utils/fieldValidators'
import { updateVirtualMachine } from '../new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { keys } from 'ramda'
import { getVolumeSpec, getDiskSpec, getDataVolumeTemplatesSpec } from '../create/helpers'
import Alert from 'core/components/Alert'

const useStyles = makeStyles<Theme>((theme) => ({
  volumeLabel: {
    display: 'inline-block',
    marginBottom: 8,
  },
  existingVolumeField: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto min-content',
  },
  removeExistingIcon: {
    color: theme.components.badge.primary.color,
    cursor: 'pointer',
  },
  addLabel: {
    width: 'fit-content',
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 8,
  },
  minusIcon: {
    color: theme.components.badge.primary.color,
    position: 'relative',
    top: 38,
    marginLeft: 16,
    cursor: 'pointer',
  },
  plusIcon: {
    color: theme.components.badge.primary.color,
  },
  fields: {
    gap: 16,
    display: 'grid',
    gridAutoRows: 'max-content',
  },
  sharedRow: {
    display: 'grid',
    gridTemplateColumns: 'auto min-content',
  },
  splitRow: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gap: 16,
  },
  diskLabel: {
    display: 'inline-block',
    marginBottom: 8,
  },
  sizeField: {
    position: 'relative',
    top: 2,
  },
  data: {
    display: 'grid',
    gridTemplateColumns: 'min-content min-content',
    gap: 16,
  },
  alert: {
    marginBottom: 16,
  },
}))

const storageSizeValidators = [minValueValidator(1), requiredValidator]

const dataVolumeColumns = [
  { id: 'name', label: 'Name' },
  {
    id: 'spec.pvc.resources.requests.storage',
    label: 'Size',
    render: (value, item) => {
      return value ? value : item?.spec?.storage?.resources?.requests?.storage
    },
  },
]

const pvcColumns = [
  { id: 'name', label: 'Name' },
  { id: 'spec.resources.requests.storage', label: 'Size' },
]

const getLastDiskIndex = (disks) => {
  if (!disks.length) {
    return 0
  }
  const lastDisk = disks[disks.length - 1]
  const nameParts = lastDisk?.volumeName?.split('-')
  if (!nameParts) {
    return 0
  }
  const index = parseInt(nameParts[nameParts.length - 1])
  return index
}

export default function EditEntityNetworksModal({ vm, open, onClose }) {
  const classes = useStyles()
  const [loaded, setLoaded] = useState(false)
  const [baseDiskIndex, setBaseDiskIndex] = useState(0)
  const defaultParams = {
    existingVolumes: [],
    newDisks: [],
  }

  const { params, getParamsUpdater, updateParams, setParams } = useParams<{
    existingVolumes?: { volumeName: string; type: string; target: string; baseDisk: boolean }[]
    newDisks?: {
      volumeName: string
      dataTemplateName: string
      rowId: string
      sourceType: string
      httpUrl?: string
      registryUrl?: string
      // Providing typing for pvcs and data volumes here conflicts with
      // ListTableField component
      selectedPvcs?: any[]
      selectedDataVolumes?: any[]
      accessMode: string
      storageSize?: number
      storageClass?: string
    }[]
  }>(defaultParams)
  const { update: updateFn, updating, error, reset } = useUpdateAction(updateVirtualMachine)

  const { loading: pvcsLoading, reload: reloadPvcs } = useListAction(listPersistentVolumeClaims, {
    params: {
      clusterId: vm?.clusterId,
    },
    requiredParams: ['clusterId'],
  })
  const pvcs = useSelectorWithParams(persistentVolumeClaimsSelector, {
    clusterId: vm?.clusterId,
    namespace: vm?.namespace,
    useGlobalParams: false,
  })

  const { loading: dataVolumesLoading, reload: reloadDataVolumes } = useListAction(
    listDataVolumes,
    {
      params: {
        clusterId: vm?.clusterId,
        namespace: vm?.namespace,
      },
      requiredParams: ['clusterId', 'namespace'],
    },
  )
  const dataVolumes = useSelectorWithParams(dataVolumesSelector, {
    clusterId: vm?.clusterId,
    namespace: vm?.namespace,
    useGlobalParams: false,
  })

  useEffect(() => {
    // Prevent resetting of form from background reloads
    if (loaded) {
      return
    }
    if (vm) {
      const existingVolumes = vm?.spec?.template?.spec?.volumes || []
      const vmiVolumes = vm?.vmi?.status?.volumeStatus || []

      const mappedVolumes = existingVolumes.map((volume, index) => {
        const volumeKeys = keys(volume)
        const volumeType = volumeKeys.filter((key) => key !== 'name')
        const volumeStatus = vmiVolumes.find((vol) => {
          return vol?.name === volume?.name
        })
        return {
          volumeName: volume?.dataVolume?.name || volume?.name,
          type: volumeType,
          target: volumeStatus?.target,
          baseDisk: index === 0,
        }
      })

      // UI checks for existing volumes with default UI naming convention
      // Get highest existing index and start new volume names above that
      const existingIndexes = existingVolumes.map((volume) => {
        const nameParts = volume?.name?.split('-')
        if (!nameParts) {
          return 0
        }
        const index = parseInt(nameParts[nameParts.length - 1])
        return Number.isInteger(index) ? index : 0
      })
      const highestIndex = existingIndexes.sort().reverse()[0] || 0
      setBaseDiskIndex(highestIndex + 1)

      updateParams({
        existingVolumes: mappedVolumes,
      })
      setLoaded(true)
    }
  }, [vm, loaded])

  const addStorageDisk = useCallback(() => {
    const newIndex = params?.newDisks?.length
      ? getLastDiskIndex(params.newDisks) + 1
      : baseDiskIndex

    updateParams({
      newDisks: [
        ...params.newDisks,
        {
          volumeName: `volume-${newIndex}`,
          dataTemplateName: `${vm.name}-disk-${newIndex}`,
          rowId: uuid.v4(),
          sourceType: '',
          storageClass: '',
          httpUrl: '',
          registryUrl: '',
          selectedPvcs: [],
          selectedDataVolumes: [],
          accessMode: '',
          storageSize: undefined,
        },
      ],
    })
  }, [params.newDisks, updateParams, baseDiskIndex])

  const updateStorageDisk = useCallback(
    (rowId, value) => {
      const idx = params.newDisks.findIndex((disk) => disk.rowId === rowId)
      if (idx < 0) {
        return
      }
      const newDisks = [...params.newDisks]
      newDisks.splice(idx, 1, {
        ...params.newDisks[idx],
        ...value,
      })
      updateParams({
        newDisks,
      })
    },
    [params.newDisks, updateParams],
  )

  const removeExistingVolume = useCallback(
    (volume) => {
      const filteredVolumes = params?.existingVolumes.filter(
        (vol) => vol.volumeName !== volume.volumeName,
      )
      updateParams({
        existingVolumes: filteredVolumes,
      })
    },
    [params?.existingVolumes, updateParams],
  )

  const removeStorageDisk = useCallback(
    (rowId) => {
      updateParams({
        newDisks: params.newDisks.filter((disk) => disk.rowId !== rowId),
      })
    },
    [params.newDisks, updateParams],
  )

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  const submitForm = useCallback(async () => {
    const dataVolumeTemplates = vm?.spec?.dataVolumeTemplates || []
    const volumes = vm?.spec?.template?.spec?.volumes || []
    const disks = vm?.spec?.template?.spec?.domain?.devices?.disks || []

    // Handle the pre-existing volumes
    const existingVolumeNames = params.existingVolumes.map((volume) => volume?.volumeName)
    // Get the volumes that user would like to keep
    const keptVolumes = volumes.filter((volume) => {
      const name = volume?.dataVolume?.name || volume?.name
      return existingVolumeNames.includes(name)
    })
    // Get disks to keep via kept volumes
    const keptVolumesDiskNames = keptVolumes.map((volume) => volume?.name)
    const keptDisks = disks.filter((disk) => {
      return keptVolumesDiskNames.includes(disk?.name)
    })
    // Todo: Backend to confirm if these can even be removed
    const keptDataVolumeTemplates = dataVolumeTemplates.filter((template) => {
      return existingVolumeNames.includes(template?.metadata?.name)
    })

    const newVolumes = getVolumeSpec(params.newDisks)
    const newDisks = getDiskSpec(params.newDisks)
    const newDataVolumeTemplates = getDataVolumeTemplatesSpec(params.newDisks)

    const body = {
      spec: {
        dataVolumeTemplates: [...keptDataVolumeTemplates, ...newDataVolumeTemplates],
        template: {
          spec: {
            domain: {
              devices: {
                disks: [...keptDisks, ...newDisks],
              },
            },
            volumes: [...keptVolumes, ...newVolumes],
          },
        },
      },
    }
    const { success } = await updateFn({
      ...vm,
      body,
      requestType: 'patch',
    })
    if (success) {
      handleClose()
    }
  }, [params, vm, handleClose])

  return (
    <ModalForm
      open={open}
      title={`Edit Storage`}
      onSubmit={submitForm}
      onClose={onClose}
      submitting={false}
      error={error}
      submitTitle={`Save Changes`}
      maxWidth={528}
    >
      <Alert
        variant="primary"
        message={
          <Text variant="body2">
            <b>Note:</b> The VM must be restarted after updating in order for changes to take
            effect.
          </Text>
        }
        className={classes.alert}
      />
      <FormFieldSection title="Existing Volumes">
        {params?.existingVolumes?.length ? (
          params?.existingVolumes?.map((volume, idx) => (
            <div key={idx}>
              <Text className={classes.volumeLabel} variant="caption1">
                {volume.volumeName} {volume?.baseDisk && '(Root Disk)'}
              </Text>
              <div className={classes.existingVolumeField}>
                <div>
                  <div className={classes.data}>
                    <Text variant="body2">Type:</Text>
                    <Text variant="caption1">{volume?.type}</Text>
                  </div>
                  {volume?.target && (
                    <div className={classes.data}>
                      <Text variant="body2">Target:</Text>
                      <Text variant="caption1">{volume?.target}</Text>
                    </div>
                  )}
                </div>
                {!volume?.baseDisk && (
                  <div className={classes.removeLabel} onClick={() => removeExistingVolume(volume)}>
                    <FontAwesomeIcon className={classes.removeExistingIcon} size="xl" solid>
                      circle-minus
                    </FontAwesomeIcon>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <Text variant="caption1">No Volumes</Text>
        )}
      </FormFieldSection>
      <FormFieldSection title="Add New">
        {params.newDisks.map((disk, idx) => (
          <div key={idx} className={classes.fields}>
            <Text className={classes.diskLabel} variant="caption1">
              {disk.dataTemplateName}
            </Text>
            <div className={classes.sharedRow}>
              <PicklistField
                DropdownComponent={VmStorageOptionPicklist}
                id={`newDisks.${idx}.sourceType`}
                label="Select an option"
                value={disk.sourceType}
                onChange={(value) => {
                  const updatedDisk = {
                    ...disk,
                    sourceType: value,
                  }
                  const newDisks = params.newDisks.map((disk) => {
                    if (disk.rowId === disk.rowId) {
                      return updatedDisk
                    }
                    return disk
                  })
                  updateParams({ newDisks })
                }}
                required
              />
              <div className={classes.removeLabel} onClick={() => removeStorageDisk(disk.rowId)}>
                <FontAwesomeIcon className={classes.minusIcon} size="xl" solid>
                  circle-minus
                </FontAwesomeIcon>
              </div>
            </div>
            {disk.sourceType === 'httpUrl' && (
              <TextField
                id={`newDisks.${idx}.httpUrl`}
                label="HTTP URL"
                onChange={(value) => updateStorageDisk(disk.rowId, { httpUrl: value })}
                value={disk.httpUrl}
                required
              />
            )}
            {disk.sourceType === 'registryUrl' && (
              <TextField
                id={`newDisks.${idx}.registryUrl`}
                label="Registry URL"
                onChange={(value) => updateStorageDisk(disk.rowId, { registryUrl: value })}
                value={disk.registryUrl}
                required
              />
            )}
            {disk.sourceType === 'disk' && (
              <>
                <Text variant="body2">
                  You can run the virtctl image-upload command as below to upload a VM image to a
                  PVC or DataVolume:
                </Text>
                <CodeBlock>
                  {`# virtctl image-upload --pvc-name=<upload-pvc> --pvc-size=<1Gi> --image-path=</your/centos/image.qcow2>
# virtctl image-upload dv dv-name --size=10Gi --image-path=/images/fedora30.qcow2`}
                </CodeBlock>
              </>
            )}
            {disk.sourceType === 'clone' && (
              <div>
                <ListTableField
                  id={`newDisks.${idx}.selectedPvcs`}
                  data={pvcs}
                  loading={pvcsLoading}
                  columns={pvcColumns}
                  onChange={(value) => updateStorageDisk(disk.rowId, { selectedPvcs: value })}
                  value={disk.selectedPvcs}
                  onReload={() => {
                    reloadPvcs(true, true)
                  }}
                  required
                />
              </div>
            )}
            {disk.sourceType === 'volume' && (
              <ListTableField
                id={`newDisks.${idx}.selectedPvcs`}
                data={dataVolumes}
                loading={dataVolumesLoading}
                columns={dataVolumeColumns}
                onChange={(value) => updateStorageDisk(disk.rowId, { selectedDataVolumes: value })}
                value={disk.selectedDataVolumes}
                onReload={() => {
                  reloadDataVolumes(true, true)
                }}
                required
              />
            )}
            <PicklistField
              DropdownComponent={AccessModePicklist}
              id={`newDisks.${idx}.accessMode`}
              label="Access Mode"
              value={disk.accessMode}
              onChange={(value) => {
                updateStorageDisk(disk.rowId, { accessMode: value })
              }}
            />
            {['httpUrl', 'registryUrl', 'disk', 'blank'].includes(disk.sourceType) && (
              <div
                className={
                  ['httpUrl', 'registryUrl', 'blank'].includes(disk.sourceType) && classes.splitRow
                }
              >
                <TextField
                  className={classes.sizeField}
                  id={`newDisks.${idx}.storageSize`}
                  label="Size (GiB)"
                  onChange={(value) => updateStorageDisk(disk.rowId, { storageSize: value })}
                  value={disk.storageSize}
                  min={1}
                  type="number"
                  validations={storageSizeValidators}
                  required
                />
                {['httpUrl', 'registryUrl', 'blank'].includes(disk.sourceType) && (
                  <PicklistField
                    DropdownComponent={StorageClassesPicklist}
                    id={`newDisks.${idx}.storageClass`}
                    label="Storage Class"
                    onChange={(value) => updateStorageDisk(disk.rowId, { storageClass: value })}
                    clusterId={vm?.clusterId}
                  />
                )}
              </div>
            )}
          </div>
        ))}
        <div className={classes.addLabel} onClick={addStorageDisk}>
          <FontAwesomeIcon className={classes.plusIcon} solid>
            plus-circle
          </FontAwesomeIcon>
          <Text variant="caption1">Add Another Storage Disk</Text>
        </div>
      </FormFieldSection>
    </ModalForm>
  )
}
