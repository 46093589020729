import React from 'react'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  migrationText: {
    display: 'inline-grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    gap: 8,
  },
}))

export default function MigrationStatus({ vmi }) {
  const classes = useStyles()
  if (!vmi || !vmi?.status?.migrationState || vmi?.status?.migrationState?.completed) {
    return null
  }
  return (
    <div>
      <Text variant="body2" className={classes.migrationText}>
        <FontAwesomeIcon spin>sync</FontAwesomeIcon>
        <div>
          Migrating Pod {vmi?.status?.migrationState?.targetPod || 'unknown'} from Node{' '}
          {vmi?.status?.migrationState?.sourceNode} to {vmi?.status?.migrationState?.targetNode}
        </div>
      </Text>
    </div>
  )
}
