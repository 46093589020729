import React, { useCallback, useEffect, useRef } from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'core/elements/Text'
import { codeMirrorOptions } from 'app/constants'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import { yamlValidator } from 'core/utils/fieldValidators'
import TextFileDrop from 'core/elements/grid/TextFileDrop'
import { getVolumeJson } from './helpers'

const useStyles = makeStyles<Theme>((theme) => ({
  fullWidth: {
    width: '100% !important',
    maxWidth: '50vw',
  },
}))

const cloudInitValidations = [yamlValidator]

export default function ConfigureStep({ wizardContext, setWizardContext, onNext }) {
  const classes = useStyles({})
  const validatorRef = useRef(null)
  const setupValidator = (validate) => {
    validatorRef.current = { validate }
  }

  const submitStep = useCallback(async () => {
    const isValid = await validatorRef.current.validate()
    if (!isValid) {
      return false
    }
    // Reset it so that it can be recalculated upon moving forward,
    // even if back button was used
    setWizardContext({
      advancedYaml: '',
    })
    if (wizardContext?.cloudInit) {
      // Update the disk & volume specs only if cloudInit is filled
      const { diskSpec, volumeSpec } = getVolumeJson({
        storageDisks: wizardContext.storageDisks,
        vmName: wizardContext.name,
        cloudInit: wizardContext.cloudInit,
      })
      setWizardContext({
        diskTemplatesObj: diskSpec,
        volumesObj: volumeSpec,
      })
    }
    return true
  }, [wizardContext?.cloudInit])

  useEffect(() => {
    onNext(submitStep)
  }, [submitStep])

  return (
    <ValidatedForm
      onSubmit={setWizardContext}
      initialValues={wizardContext}
      triggerSubmit={setupValidator}
      elevated={false}
    >
      <FormFieldSection title="Configure Instance Details">
        <Text variant="body2">
          Upload the Cloud-Init script which will appear in console below, or enter it manually.
        </Text>
        <Text variant="body2">
          <b>Please note:</b> This step is optional and can be skipped.
        </Text>
        <TextFileDrop
          onChange={(value) => setWizardContext({ cloudInit: value })}
          fileTypes={['.yaml', '.yml']}
        />
        <CodeMirror
          id="cloudInit"
          label="Cloud-Init Script"
          validations={cloudInitValidations}
          onChange={(value) => setWizardContext({ cloudInit: value })}
          value={wizardContext.cloudInit}
          options={codeMirrorOptions}
          className={classes.fullWidth}
        />
      </FormFieldSection>
    </ValidatedForm>
  )
}
