import React, { useCallback, useMemo } from 'react'
import Text from 'core/elements/Text'
import ModalForm from 'core/elements/modal/ModalForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import useUpdateAction from 'core/hooks/useUpdateAction'
import {
  powerVirtualMachineInstance,
  powerVirtualMachine,
  deleteVirtualMachine,
  deleteVirtualMachineInstance,
  migrateVirtualMachineInstance,
  listLiveMigrations,
} from './new-actions'
import useListAction from 'core/hooks/useListAction'
import { uniq } from 'ramda'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import Grid from 'core/elements/grid'

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'grid',
    gap: 16,
  },
  vmList: {
    display: 'flex',
    gap: 16,
    flexWrap: 'wrap',
    maxHeight: 180,
    overflow: 'auto',
    border: `1px solid ${theme.components.table.border}`,
    borderRadius: 4,
    padding: '16px 32px',
  },
}))

const titleMap = {
  restart: 'Restart',
  start: 'Start',
  stop: 'Stop',
  pause: 'Pause',
  unpause: 'Unpause',
  liveMigration: 'Live Migrate',
  delete: 'Delete',
}

const getValidVms = (rows, action) => {
  if (['start', 'stop'].includes(action)) {
    return {
      validRows: rows?.filter((item) => !!item?.vm),
      invalidRows: rows?.filter((item) => !item?.vm),
    }
  } else if (action === 'restart') {
    return {
      validRows: rows?.filter(
        (item) => !!item?.vm && item?.vm?.status?.printableStatus !== 'Stopped',
      ),
      invalidRows: rows?.filter(
        (item) => !item?.vm || item?.vm?.status?.printableStatus === 'Stopped',
      ),
    }
  } else if (['pause', 'unpause'].includes(action)) {
    return {
      validRows: rows?.filter((item) => !!item?.vmi),
      invalidRows: rows?.filter((item) => !item?.vmi),
    }
  } else if (action === 'liveMigration') {
    return {
      validRows: rows?.filter((item) => {
        const condition = item?.vmi?.status?.conditions?.find(
          (cond) => cond.type === 'LiveMigratable',
        )
        return condition?.status === 'True'
      }),
      invalidRows: rows?.filter((item) => {
        const condition = item?.vmi?.status?.conditions?.find(
          (cond) => cond.type === 'LiveMigratable',
        )
        return !condition || condition?.status !== 'True'
      }),
    }
  }
  return { validRows: rows, invalidRows: [] }
}

const getInvalidReason = (row, action) => {
  if (['start', 'stop'].includes(action)) {
    return `No VirtualMachine object found to perform ${action} action on`
  } else if (action === 'restart') {
    return row?.vm?.status?.printableStatus === 'Stopped'
      ? 'The restart action may not be performed on a Stopped VM'
      : `No VirtualMachine object found to perform ${action} action on`
  } else if (['pause', 'unpause'].includes(action)) {
    return `No VirtualMachineInstance object found to perform ${action} action on`
  } else if (action === 'liveMigration') {
    if (!row?.vmi) {
      return 'No VirtualMachineInstance object found to live migrate'
    }
    const condition = row?.vmi?.status?.conditions?.find((cond) => cond.type === 'LiveMigratable')
    return condition?.message || 'N/A'
  }
  return 'N/A'
}

// Don't want line clamp from default CellComponent
const ReasonCell = ({ value }) => {
  return <Text variant="body2">{value}</Text>
}

const invalidRowColumns = [
  {
    key: 'id',
    label: 'ID',
    display: false,
  },
  {
    key: 'name',
    label: 'VM Name',
  },
  {
    key: 'reason',
    label: 'Reason',
    CellComponent: ReasonCell,
  },
]

const getDescriptionText = (validRows, action) => {
  const numVms = validRows?.length
  return {
    restart: `Attempt to restart the following ${numVms} VMs`,
    start: `Attempt to start the following ${numVms} VMs`,
    stop: `Attempt to stop the following ${numVms} VMs`,
    pause: `Attempt to pause the following ${numVms} VMs`,
    unpause: `Attempt to unpause the following ${numVms} VMs`,
    liveMigration: `Attempt to live migrate the following ${numVms} VMs`,
    delete: `Attempt to delete the following ${numVms} VMs`,
  }[action]
}

const ValidRowsList = ({ validRows }) => {
  const classes = useStyles()
  return (
    <div className={classes.vmList}>
      {validRows?.map((row) => (
        <Text variant="caption1" key={row.id}>
          {row.name}
        </Text>
      ))}
    </div>
  )
}

export default function BulkActionDialog({ rows, onClose, action }) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const { update: powerVm, updating: poweringVm } = useUpdateAction(powerVirtualMachine)
  const { update: powerVmi, updating: poweringVmi } = useUpdateAction(powerVirtualMachineInstance)
  const { update: deleteVm, updating: deletingVm } = useUpdateAction(deleteVirtualMachine)
  const { update: deleteVmi, updating: deletingVmi } = useUpdateAction(deleteVirtualMachineInstance)
  const { update: migrateVmi, updating: migratingVmi } = useUpdateAction(
    migrateVirtualMachineInstance,
  )
  const submitting = poweringVm || poweringVmi || deletingVm || deletingVmi || migratingVmi

  // Get cluster IDs for reloading live migrations later
  const clusterIds: string[] = uniq(rows.map((row) => row?.clusterId))
  const { reload: reloadLiveMigrations } = useListAction(listLiveMigrations, {
    params: {
      clusterId: clusterIds,
    },
    requiredParams: ['clusterId'],
  })

  const { validRows, invalidRows } = getValidVms(rows, action)
  const descriptionText = getDescriptionText(validRows, action)

  const invalidRowsWithReason = useMemo(() => {
    return invalidRows?.map((row) => ({
      name: row?.name,
      reason: getInvalidReason(row, action),
      id: row?.id,
    }))
  }, [invalidRows, action])

  const handleSubmit = useCallback(async () => {
    const promises = validRows.map((row) => {
      if (action === 'delete') {
        if (row?.vm) {
          return deleteVm(row?.vm)
        } else {
          return deleteVmi(row?.vmi)
        }
      } else if (['restart', 'start', 'stop'].includes(action)) {
        return powerVm({ ...row?.vm, operation: action })
      } else if (['pause', 'unpause'].includes(action)) {
        return powerVmi({ ...row?.vmi, operation: action })
      } else if (action === 'liveMigration') {
        return migrateVmi({ ...row?.vmi, vmiName: row?.vmi?.name, refreshLiveMigrations: false })
      }
      return null
    })
    await Promise.allSettled(promises)
    if (action === 'liveMigration') {
      reloadLiveMigrations(true, true)
      history.push(routes.liveMigrations.overview.path())
    }
    onClose(true)
  }, [validRows, action])

  return (
    <ModalForm
      open
      onClose={onClose}
      panel="dialog"
      title={`${titleMap[action]} ${rows?.length === 1 ? 'VM' : 'VMs'}`}
      onSubmit={handleSubmit}
      submitTitle={`${titleMap[action]} ${validRows?.length} ${
        validRows?.length === 1 ? 'VM' : 'VMs'
      }`}
      disableSubmit={validRows?.length === 0}
      submitting={submitting}
      maxWidth={invalidRowsWithReason?.length ? 700 : undefined}
    >
      <div className={classes.container}>
        {validRows?.length ? (
          <>
            <Text variant="body2">{descriptionText}</Text>
            <ValidRowsList validRows={validRows} />
          </>
        ) : (
          <Text variant="caption1">No valid VMs for {action} action</Text>
        )}
        {!!invalidRowsWithReason?.length && (
          <>
            <Text variant="body2">
              <b>Note</b>: The {action} action may not be performed on the following VMs:
            </Text>
            <Grid
              uniqueIdentifier="id"
              data={invalidRowsWithReason}
              columns={invalidRowColumns}
              rowsPerPage={5}
              showResultsPerPageDropdown={false}
              disableToolbar
            />
          </>
        )}
      </div>
    </ModalForm>
  )
}
