import React, { useState } from 'react'
import FormWrapper from 'core/components/FormWrapper'
import Wizard from 'core/components/wizard/Wizard'
import WizardStep from 'core/components/wizard/WizardStep'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createVirtualMachine } from '../new-actions'
import MethodStep from './MethodStep'
import BasicStep from './BasicStep'
import StorageStep from './StorageStep'
import ResourceStep from './ResourceStep'
import NetworkStep from './NetworkStep'
import CloudInitStep from './CloudInitStep'
import AdvancedConfigurationStep from './AdvancedConfigurationStep'
import ReviewStep from './ReviewStep'
import YamlReviewStep from './YamlReviewStep'
import { VirtualMachineResourceTypes } from '../model'
import { YamlTemplate } from 'core/components/wizard/YamlTemplates'
import vmTemplate from './vmTemplate.json'
import YamlStep from './YamlStep'
import { MethodHelp, YamlHelp, BasicHelp, NetworksHelp, CloudInitHelp } from './HelpComponents'

const initialContext = {
  name: '',
  names: [],
  clusterId: undefined,
  yaml: undefined,
  advancedYaml: '',
  vmType: VirtualMachineResourceTypes.VirtualMachine,
  sourceType: 'url',
  resourceOption: 'custom',
  ramUnit: 'Gi',
  requestMemoryUnit: 'Gi',
  storageDisks: [],
  selectedNetworks: [],
  runStrategy: 'Manual',
  podNetworkBindingType: 'masquerade',
  method: '',
}

const yamlTemplates: YamlTemplate[] = [
  {
    title: 'VM',
    schema: vmTemplate,
  },
]

export default function CreateVirtualMachineWizard() {
  const { history } = useReactRouter()
  const [method, setMethod] = useState(null)

  const {
    update: createVm,
    updating: creatingVirtualMachine,
    error: errorCreatingVirtualMachine,
  } = useUpdateAction(createVirtualMachine)

  const submitMethod = ({ method }) => {
    setMethod(method)
  }

  const submitYaml = async (wizardContext) => {
    const addBody = {
      yaml: wizardContext?.yaml,
      clusterId: wizardContext?.clusterId,
      namespace: wizardContext?.namespace,
    }
    const { success } = await createVm(addBody)
    if (!success) return
    history.push(routes.kubevirt.overview.path())
  }

  const submitWizard = async (wizardContext) => {
    const addBody = {
      yaml: wizardContext.advancedYaml,
      clusterId: wizardContext.clusterId,
      namespace: wizardContext.namespace,
    }
    const { success } = await createVm(addBody)
    if (!success) return
    if (wizardContext.multiVmYamls?.length) {
      const batchVmsToCreate = wizardContext.multiVmYamls.map((yamlObj) => ({
        yaml: yamlObj.yaml,
        clusterId: wizardContext.clusterId,
        namespace: wizardContext.namespace,
      }))
      const result = await Promise.all(batchVmsToCreate.map(createVm))
      await Promise.allSettled(batchVmsToCreate.map(createVm))
    }
    history.push(routes.kubevirt.overview.path())
  }

  return (
    <FormWrapper
      backUrl={routes.kubevirt.overview.path()}
      loading={creatingVirtualMachine}
      message={errorCreatingVirtualMachine}
    >
      <DocumentMeta title="Create Virtual Machine" bodyClasses={['form-view']} />
      {method === null && (
        <Wizard
          onComplete={submitMethod}
          originPath={routes.kubevirt.overview.path()}
          hideAllButtons
          showExitConfirmationDialog
        >
          {({ wizardContext, setWizardContext, onNext, handleNext }) => (
            <WizardStep
              stepId="methodStep"
              label="Select Method"
              keepContentMounted={false}
              summaryTitle="Create Virtual Machine"
              summaryIcon="kubevirt"
              asideOptions={['help']}
              HelpComponent={MethodHelp}
            >
              <MethodStep
                wizardContext={wizardContext}
                setWizardContext={setWizardContext}
                onNext={onNext}
                handleNext={handleNext}
              />
            </WizardStep>
          )}
        </Wizard>
      )}
      {method === 'yaml' && (
        <Wizard
          error={errorCreatingVirtualMachine}
          onComplete={submitYaml}
          context={initialContext}
          showCustomButton={(state) => state?.activeStepId === 'yamlStep'}
          customAction={() => setMethod(null)}
          customButtonText="Back"
          showExitConfirmationDialog
        >
          {({ wizardContext, setWizardContext, onNext, handleNext }) => (
            <>
              <WizardStep
                stepId="yamlStep"
                label="Upload YAML"
                keepContentMounted={false}
                summaryTitle="Create Virtual Machine"
                summaryIcon="kubevirt"
                asideOptions={['help']}
                HelpComponent={YamlHelp}
              >
                <YamlStep
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                />
              </WizardStep>
              <WizardStep
                stepId="yamlReviewStep"
                label="Finalize and Review"
                keepContentMounted={false}
                summaryTitle="Create Virtual Machine"
                summaryIcon="kubevirt"
              >
                <YamlReviewStep wizardContext={wizardContext} />
              </WizardStep>
            </>
          )}
        </Wizard>
      )}
      {method === 'wizard' && (
        <Wizard
          error={errorCreatingVirtualMachine}
          onComplete={submitWizard}
          context={initialContext}
          showCustomButton={(state) => state?.activeStepId === 'basicStep'}
          customAction={() => setMethod(null)}
          customButtonText="Back"
          showExitConfirmationDialog
        >
          {({ wizardContext, setWizardContext, onNext, handleNext }) => (
            <>
              <WizardStep
                stepId="basicStep"
                label="Basic Settings"
                keepContentMounted={false}
                summaryTitle="Create Virtual Machine"
                summaryIcon="kubevirt"
                yamlTemplates={yamlTemplates}
                showSummaryLabels={false}
                showSummaryYamls
                asideOptions={['yaml', 'help']}
                HelpComponent={BasicHelp}
              >
                <BasicStep
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                  initialContext={initialContext}
                />
              </WizardStep>
              <WizardStep
                stepId="storageStep"
                label="Storage"
                keepContentMounted={false}
                summaryTitle="Create Virtual Machine"
                summaryIcon="kubevirt"
                yamlTemplates={yamlTemplates}
                showSummaryLabels={false}
                showSummaryYamls
                asideOptions={['yaml']}
              >
                <StorageStep
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                />
              </WizardStep>
              <WizardStep
                stepId="resourceStep"
                label="Resource"
                keepContentMounted={false}
                summaryTitle="Create Virtual Machine"
                summaryIcon="kubevirt"
                yamlTemplates={yamlTemplates}
                showSummaryLabels={false}
                showSummaryYamls
                asideOptions={['yaml']}
              >
                <ResourceStep
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                />
              </WizardStep>
              <WizardStep
                stepId="networkStep"
                label="Network"
                keepContentMounted={false}
                summaryTitle="Create Virtual Machine"
                summaryIcon="kubevirt"
                yamlTemplates={yamlTemplates}
                showSummaryLabels={false}
                showSummaryYamls
                asideOptions={['yaml', 'help']}
                HelpComponent={NetworksHelp}
              >
                <NetworkStep
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                />
              </WizardStep>
              <WizardStep
                stepId="cloudInitStep"
                label="Cloud-Init"
                keepContentMounted={false}
                summaryTitle="Create Virtual Machine"
                summaryIcon="kubevirt"
                yamlTemplates={yamlTemplates}
                showSummaryLabels={false}
                showSummaryYamls
                asideOptions={['yaml', 'help']}
                HelpComponent={CloudInitHelp}
              >
                <CloudInitStep
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                />
              </WizardStep>
              <WizardStep
                stepId="advancedConfigStep"
                label="Advanced Configuration"
                keepContentMounted={false}
                summaryTitle="Create Virtual Machine"
                summaryIcon="kubevirt"
              >
                <AdvancedConfigurationStep
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                />
              </WizardStep>
              <WizardStep
                stepId="reviewStep"
                label="Finalize and Review"
                keepContentMounted={false}
                summaryTitle="Create Virtual Machine"
                summaryIcon="kubevirt"
              >
                <ReviewStep wizardContext={wizardContext} />
              </WizardStep>
            </>
          )}
        </Wizard>
      )}
    </FormWrapper>
  )
}
