import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import { trackEvent } from 'utils/tracking'
import { routes } from 'core/utils/routes'
import Bugsnag from 'utils/bugsnag'
import { deleteVirtualMachine } from './new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Modal from 'core/elements/modal'

const VirtualMachineDeleteDialog = ({ rows: [virtualMachine], onClose }) => {
  const { history } = useReactRouter()
  const { update: deleteFn, updating: deletingVirtualMachine } = useUpdateAction(
    deleteVirtualMachine,
  )
  const title = `Permanently delete VM "${virtualMachine?.name}"?`
  const handleDelete = useCallback(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to delete VM', {
      cluster_uuid: virtualMachine?.clusterId,
      vm_name: virtualMachine?.name,
      vm_namespace: virtualMachine?.namespace,
    })
    await deleteFn(virtualMachine)
    trackEvent('Delete VM', {
      cluster_uuid: virtualMachine?.clusterId,
      vm_name: virtualMachine?.name,
      vm_namespace: virtualMachine?.namespace,
    })
    onClose(true)
    history.push(routes.kubevirt.overview.path())
  }, [virtualMachine])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={title}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingVirtualMachine}>
            Don't Delete
          </Button>
          <Button onClick={handleDelete} loading={deletingVirtualMachine}>
            Delete VM
          </Button>
        </>
      }
    >
      <Text variant="body2">This action cannot be undone, are you sure you want to do this?</Text>
    </Modal>
  )
}

export default VirtualMachineDeleteDialog
