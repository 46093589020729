import Plugin from 'core/plugins/plugin'
import { AppPlugins } from 'app/constants'
import { kubevirtLogo } from './logo'
import VirtualMachineDetailPage from './components/virtual-machines/details'
import CreateVirtualMachineWizard from './components/virtual-machines/create/CreateVirtualMachineWizard'
import EditVirtualMachinePage from './components/virtual-machines/EditVmPage'
import VmOverviewPage from './components/virtual-machines'
import VirtualMachineDetailsPage from './components/virtual-machines/vm-details/VirtualMachineDetailsPage'
import VirtualMachineInstanceDetailsPage from './components/virtual-machines/vmi-details/VirtualMachineInstanceDetailsPage'
import LiveMigrationsOverview from './components/virtual-machines/live-migrations/LiveMigrationsOverview'
import KubevirtDashboard from './components/dashboard/KubevirtDashboard'
import InstanceTypesListPage from './components/virtual-machines/instance-types/InstanceTypesListPage'
import CreateInstanceTypePage from './components/virtual-machines/instance-types/CreateInstanceTypePage'
import KubevirtEventsPage from './components/events/KubevirtEventsPage'
import KubevirtClustersListPage from './components/dashboard/KubevirtClustersListPage'
import KubevirtNodesListPage from './components/dashboard/KubevirtNodesListPage'

// K8s duplicate screens
import CSIDriversListPage from 'k8s/components/storage/csi-drivers/CsiDriversListPage'
import StorageDetailsPage from 'k8s/components/storage/details/StorageClassDetailsPage'
import PersistentVolumeClaimsListPage from 'k8s/components/storage/persistent-volume-claims/PersistentVolumeClaimsListPage'
import PersistentVolumesListPage from 'k8s/components/storage/persistent-volume/PersistentVolumesListPage'
import PersistentVolumeClaimDetailsPage from 'k8s/components/storage/persistent-volume-claims/details/PersistentVolumeClaimDetailsPage'
import PersistentVolumeDetailsPage from 'k8s/components/storage/persistent-volume/details/PersistentVolumeDetailsPage'
import StorageClassListPage from 'k8s/components/storage/storage-classes/StorageClassesListPage'
import DataVolumesListPage from 'k8s/components/storage/data-volumes/DataVolumesListPage'
import DataVolumeDetailsPage from 'k8s/components/storage/data-volumes/details/DataVolumeDetailsPage'
import PodOverviewPage from 'k8s/components/pods'
import PodContainerDetailsPage from 'k8s/components/pods/pod-container-details-page'
import PodsListPage from 'k8s/components/pods/PodsListPage'
import ServiceOverviewPage from 'k8s/components/services'
import ServicesListPage from 'k8s/components/services/ServicesListPage'
import NetworkPluginsListPage from 'k8s/components/networking/plugins/NetworkPluginsListPage'
import NetworkPluginsDetailsPage from 'k8s/components/networking/plugins/details/NetworkPluginsDetailsPage'
import NetworkAttachmentDefinitionsListPage from 'k8s/components/networking/network/NetworkAttachmentDefinitionsListPage'
import NetworkAttachmentDefinitionsDetailsPage from 'k8s/components/networking/network/details/NetworkAttachmentDefinitionsDetailsPage'
import HostConfigurationsListPage from 'k8s/components/networking/host-configs/HostConfigurationsListPage'
import HostConfigurationDetailsPage from 'k8s/components/networking/host-configs/details/HostConfigurationDetailsPage'
import ConfigMapsListPage from 'k8s/components/configuration/config-maps/ConfigMapsListPage'
import ConfigMapsDetailsPage from 'k8s/components/configuration/config-maps/details/ConfigMapsDetailsPage'
import SecretsListPage from 'k8s/components/configuration/secrets/SecretsListPage'
import SecretsDetailsPage from 'k8s/components/configuration/secrets/details/SecretsDetailsPage'

// Infrastructure duplicate screens
import ClusterDetailsPage from 'app/plugins/infrastructure/components/clusters/ClusterDetailsPage'
import NodeDetailsPage from 'app/plugins/infrastructure/components/nodes/NodeDetailsPage'

const kubevirtPlugin = new Plugin(AppPlugins.KubeVirt, 'KubeVirt', '/ui/kubevirt', kubevirtLogo)

kubevirtPlugin.registerRoutes([
  {
    name: 'Kubevirt Dashboard',
    link: {
      path: '/dashboard/overview',
      exact: true,
      default: true,
    },
    component: KubevirtDashboard,
  },
  {
    name: 'Virtual Machines',
    link: {
      path: '/virtual-machines/virtual-machines',
      exact: true,
    },
    component: VmOverviewPage,
  },
  {
    name: 'Virtual Machine Details',
    link: { path: '/virtual-machines/clusters/:clusterId/virtual-machines/:id/:tab?', exact: true },
    component: VirtualMachineDetailsPage,
  },
  {
    name: 'Virtual Machine Instance Details',
    link: {
      path: '/virtual-machines/clusters/:clusterId/virtual-machine-instances/:name/:tab?',
      exact: true,
    },
    component: VirtualMachineInstanceDetailsPage,
    requiredRoles: 'admin',
  },
  {
    name: 'Create Virtual Machine',
    link: { path: '/virtual-machines/create/new', exact: true },
    component: CreateVirtualMachineWizard,
  },
  {
    name: 'Edit Virtual Machine',
    link: { path: '/virtual-machines/clusters/:clusterId/vms/edit/:id', exact: true },
    requiredRoles: 'admin',
    component: EditVirtualMachinePage,
  },
  {
    name: 'Virtual Machine Details',
    link: { path: '/virtual-machines/:clusterId/:namespace/:name', exact: true },
    component: VirtualMachineDetailPage,
  },
  {
    name: 'Live Migrations',
    link: { path: '/virtual-machines/live-migrations', exact: true },
    component: LiveMigrationsOverview,
  },
  {
    name: 'Instance Types',
    link: { path: '/virtual-machines/instance-types', exact: true },
    component: InstanceTypesListPage,
  },
  {
    name: 'Create Instance Type',
    link: { path: '/virtual-machines/instance-types/new', exact: true },
    requiredRoles: 'admin',
    component: CreateInstanceTypePage,
  },
  {
    name: 'Events',
    link: { path: '/events', exact: true },
    component: KubevirtEventsPage,
  },
  {
    name: 'CSI Drivers',
    link: { path: '/storage/csi-drivers', exact: true },
    component: CSIDriversListPage,
  },
  {
    name: 'Persistent Volumes',
    link: { path: '/storage/persistent-volumes/(add)?', exact: true },
    component: PersistentVolumesListPage,
  },
  {
    name: 'Persistent Volume Details',
    link: { path: '/storage/clusters/:clusterId/persistent-volumes/:id/:tab?', exact: true },
    component: PersistentVolumeDetailsPage,
  },
  {
    name: 'Persistent Volume Claims',
    link: { path: '/storage/persistent-volume-claims/(add)?', exact: true },
    component: PersistentVolumeClaimsListPage,
  },
  {
    name: 'Persistent Volume Claim Details',
    link: { path: '/storage/clusters/:clusterId/persistent-volume-claims/:id/:tab?', exact: true },
    component: PersistentVolumeClaimDetailsPage,
  },
  {
    name: 'Storage Classes',
    link: { path: '/storage/storage-classes/(add)?', exact: true },
    component: StorageClassListPage,
  },
  {
    name: 'Storage Class Details',
    link: { path: '/storage/clusters/:clusterId/storage-classes/:id/:tab?', exact: true },
    component: StorageDetailsPage,
  },
  {
    name: 'Data Volumes',
    link: { path: '/storage/data-volumes/(add)?', exact: true },
    component: DataVolumesListPage,
  },
  {
    name: 'Data Volume Details',
    link: { path: '/storage/clusters/:clusterId/data-volumes/:id/:tab?', exact: true },
    component: DataVolumeDetailsPage,
  },
  {
    name: 'Pods',
    link: { path: '/workloads/pods/(add)?', exact: true },
    component: PodsListPage,
  },
  {
    name: 'Pod Details',
    link: { path: '/workloads/clusters/:clusterId/pods/:id/:tab?', exact: true },
    component: PodOverviewPage,
  },
  {
    name: 'Pod Container Details',
    link: {
      path: '/workloads/clusters/:clusterId/pods/:id/containers/:containerName',
      exact: true,
    },
    component: PodContainerDetailsPage,
  },
  {
    name: 'Services',
    link: { path: '/workloads/services/(add)?', exact: true },
    component: ServicesListPage,
  },
  {
    name: 'Service Details',
    link: { path: '/workloads/clusters/:clusterId/services/:id/:tab?', exact: true },
    component: ServiceOverviewPage,
  },
  {
    name: 'Network',
    link: { path: '/networking/network-attachment-definitions/(add)?', exact: true },
    component: NetworkAttachmentDefinitionsListPage,
  },
  {
    name: 'Network Details',
    link: {
      path: '/networking/clusters/:clusterId/network-attachment-definitions/:id/:tab?',
      exact: true,
    },
    component: NetworkAttachmentDefinitionsDetailsPage,
  },
  {
    name: 'Network Plugins',
    link: { path: '/networking/plugins/(add)?', exact: true },
    component: NetworkPluginsListPage,
  },
  {
    name: 'Network Plugins Details',
    link: { path: '/networking/clusters/:clusterId/plugins/:id/:tab?', exact: true },
    component: NetworkPluginsDetailsPage,
  },
  {
    name: 'Host Configurations',
    link: { path: 'networking/host-configurations/(add)?', exact: true },
    component: HostConfigurationsListPage,
  },
  {
    name: 'Host Configuration Details',
    link: { path: '/networking/clusters/:clusterId/host-configurations/:id/:tab?', exact: true },
    component: HostConfigurationDetailsPage,
  },
  {
    name: 'Kubevirt Clusters',
    link: { path: '/dashboard/clusters', exact: true },
    component: KubevirtClustersListPage,
  },
  {
    name: 'Cluster Details',
    link: { path: '/dashboard/clusters/:id/:tab?', exact: true },
    component: ClusterDetailsPage,
  },
  {
    name: 'Kubevirt Nodes',
    link: { path: '/dashboard/nodes', exact: true },
    component: KubevirtNodesListPage,
  },
  {
    name: 'Node Details',
    link: { path: '/dashboard/nodes/:id', exact: true },
    component: NodeDetailsPage,
  },
  {
    name: 'Config Maps',
    link: { path: '/configuration/config-maps/(add)?', exact: true },
    component: ConfigMapsListPage,
  },
  {
    name: 'Config Maps Details',
    link: { path: '/configuration/clusters/:clusterId/config-maps/:id/:tab?', exact: true },
    component: ConfigMapsDetailsPage,
  },
  {
    name: 'Secrets',
    link: { path: '/configuration/secrets/(add)?', exact: true },
    component: SecretsListPage,
  },
  {
    name: 'Secrets Details',
    link: { path: '/configuration/clusters/:clusterId/secrets/:id/:tab?', exact: true },
    component: SecretsDetailsPage,
  },
])

const navItems = [
  {
    name: 'Dashboards',
    icon: 'tachometer',
    link: { path: '/dashboard', definition: '/dashboard/:page/:id?' },
    nestedLinks: [
      { name: 'Overview', link: { path: 'dashboard/overview' } },
      { name: 'Clusters', link: { path: 'dashboard/clusters' } },
      { name: 'Nodes', link: { path: 'dashboard/nodes' } },
    ],
  },
  {
    name: 'Virtual Machines',
    icon: 'hard-drive',
    link: { path: '/virtual-machines/virtual-machines', definition: '/virtual-machines/:page' },
    nestedLinks: [
      {
        name: 'All VMs',
        link: {
          path: 'virtual-machines/virtual-machines',
          definition:
            '/virtual-machines/(clusters)?/:id?/(virtual-machines|virtual-machine-instances)',
        },
      },
      { name: 'Live Migrations', link: { path: 'virtual-machines/live-migrations' } },
      { name: 'Instance Types', link: { path: 'virtual-machines/instance-types' } },
    ],
  },
  {
    name: 'Workloads',
    link: { path: '/workloads/pods', definition: '/workloads/(clusters)?/:id?/:page' },
    icon: 'cubes',
    nestedLinks: [
      {
        name: 'Pods',
        link: {
          path: '/workloads/pods',
          definition: '/workloads/(clusters)?/:id?/pods',
        },
      },
      {
        name: 'Services',
        link: {
          path: '/workloads/services',
          definition: '/workloads/(clusters)?/:id?/services',
        },
      },
    ],
  },
  {
    name: 'Configuration',
    link: {
      path: '/configuration/config-maps',
      definition: '/configuration/(clusters)?/:id?/:page?/(edit)?',
    },
    icon: 'screwdriver-wrench',
    nestedLinks: [
      {
        name: 'Config Maps',
        link: {
          path: '/configuration/config-maps',
          definition: '/configuration/(clusters)?/:id?/config-maps',
        },
      },
      {
        name: 'Secrets',
        link: {
          path: '/configuration/secrets',
          definition: '/configuration/(clusters)?/:id?/secrets',
        },
      },
    ],
  },
  {
    name: 'Storage',
    icon: 'coins',
    link: {
      path: '/storage/storage-classes',
      definition: '/storage/(clusters)?/:id?/:page',
    },
    nestedLinks: [
      {
        name: 'CSI Drivers',
        link: {
          path: '/storage/csi-drivers',
          definition: '/storage/(clusters)?/:id?/csi-drivers',
        },
      },
      {
        name: 'Data Volumes',
        link: {
          path: '/storage/data-volumes',
          definition: '/storage/(clusters)?/:id?/data-volumes',
        },
      },
      {
        name: 'Persistent Volumes',
        link: {
          path: '/storage/persistent-volumes',
          definition: '/storage/(clusters)?/:id?/persistent-volumes',
        },
      },
      {
        name: 'Persistent Volume Claims',
        link: {
          path: '/storage/persistent-volume-claims',
          definition: '/storage/(clusters)?/:id?/persistent-volume-claims',
        },
      },
      {
        name: 'Storage Classes',
        link: {
          path: '/storage/storage-classes',
          definition: '/storage/(clusters)?/:id?/storage-classes',
        },
      },
    ],
  },
  {
    name: 'Networking',
    icon: 'chart-network',
    link: {
      path: '/networking/network-attachment-definitions',
      definition: '/networking/(clusters)?/:id?/:page',
    },
    nestedLinks: [
      {
        name: 'Networks',
        link: {
          path: '/networking/network-attachment-definitions',
          definition: '/networking/(clusters)?/:id?/network-attachment-definitions',
        },
      },
      {
        name: 'Host Configurations',
        link: {
          path: 'networking/host-configurations',
          definition: '/networking/(clusters)?/:id?/host-configurations',
        },
      },
      {
        name: 'Plugins',
        link: { path: 'networking/plugins', definition: '/networking/(clusters)?/:id?/plugins' },
      },
    ],
  },
  {
    name: 'Events',
    icon: 'analytics',
    link: { path: '/events', definition: '/events' },
  },
]

kubevirtPlugin.registerNavItems(navItems)

export default kubevirtPlugin
