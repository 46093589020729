import { createSharedSelector } from 'core/utils/selectorHelpers'
import { virtualMachinesSelector, liveMigrationsSelector } from '../virtual-machines/selectors'
import { storageClassSelector } from 'k8s/components/storage/storage-classes/selectors'
import { persistentVolumeSelector } from 'k8s/components/storage/persistent-volume/selectors'
import { persistentVolumeClaimsSelector } from 'k8s/components/storage/persistent-volume-claims/selectors'
import { dataVolumesSelector } from 'k8s/components/storage/data-volumes/selectors'
import { alertsSelector } from 'k8s/components/alarms/selectors'

export const kubevirtDashboardResourcesSelector = createSharedSelector(
  virtualMachinesSelector,
  storageClassSelector,
  persistentVolumeSelector,
  persistentVolumeClaimsSelector,
  dataVolumesSelector,
  liveMigrationsSelector,
  alertsSelector,
  (vms, storageClasses, pvs, pvcs, dataVolumes, liveMigrations, alarms) => {
    return {
      vms,
      runningVms: vms?.filter((vm) => vm?.status?.printableStatus === 'Running'),
      pausedVms: vms?.filter((vm) => vm?.status?.printableStatus === 'Paused'),
      stoppedVms: vms?.filter((vm) => vm?.status?.printableStatus === 'Stopped'),
      migratingVms: vms?.filter((vm) => vm?.status?.printableStatus === 'Migrating'),
      otherVms: vms?.filter(
        (vm) =>
          !['Running', 'Paused', 'Stopped', 'Migrating'].includes(vm?.status?.printableStatus),
      ),
      storageClasses,
      pvs,
      pvcs,
      dataVolumes,
      liveMigrations,
      alarms: alarms?.filter((alarm) => alarm?.name?.toLowerCase()?.includes('kubevirt')),
    }
  },
)
