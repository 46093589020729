import React, { useMemo } from 'react'
import { pick, isNil, uniq } from 'ramda'
import NodeDeAuthDialog from 'app/plugins/infrastructure/components/nodes/NodeDeAuthDialog'
import RemoteSupportDialog from 'app/plugins/infrastructure/components/nodes/RemoteSupportDialog'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import NodeAuthDialog from 'app/plugins/infrastructure/components/nodes/NodeAuthDialog'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import NodeForceRemoveDialog from 'app/plugins/infrastructure/components/nodes/NodeForceRemoveDialog'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import ListContainer from 'core/containers/ListContainer'
import DocumentMeta from 'core/components/DocumentMeta'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import NodeDetailCell from 'app/plugins/infrastructure/components/nodes/node-cells/NodeDetailCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import NetworkInterfacesCell from 'app/plugins/infrastructure/components/nodes/node-cells/NetworkInterfacesCell'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import StatsCell from 'app/plugins/infrastructure/components/common/cells/StatsCell'
import UUIDCell from 'app/plugins/infrastructure/components/common/cells/UUIDCell'
import InferActionParams from 'core/actions/InferActionParams'
import { ICapiHostsSelector } from 'app/plugins/infrastructure/components/clusters/capi/details/overview/hosts/model'
import { AwsClusterTypes } from 'app/plugins/infrastructure/components/clusters/capi/model'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import { kubevirtNodesSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import { BadgeVariant } from 'core/elements/badge/Badge'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import StringMultiDropdownFilter from 'core/elements/grid/filters/StringMultiDropdownFilter'
import { isUnassignedNode } from 'app/plugins/infrastructure/components/nodes/helpers'

type ModelDataKey = DataKeys.Nodes
type SelectorModel = INodesSelector & ICapiHostsSelector
type ActionParams = InferActionParams<typeof listNodes>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const readyMap = {
  True: 'success',
  False: 'error',
}
const readyLabelMap = {
  True: 'Ready',
  False: 'Not Ready',
}

const getReadyStatus = (ready) => {
  const status: BadgeVariant = readyMap[ready] || 'unknown'
  const label = readyLabelMap[ready] || 'Unknown'
  return { variant: status, label }
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'uuid',
    label: 'UUID',
    tooltip:
      'This is the unique ID generated by PMK for this node. You will need this ID to perform any API based operations with PMK',
    CellComponent: UUIDCell,
    display: false,
  } as GridViewColumn<SelectorModel, 'uuid'>,
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: NodeDetailCell,
  },
  {
    key: 'ready',
    label: 'Status',
    CellComponent: createGridStatusCell({
      dataFn: getReadyStatus,
    }),
  } as GridViewColumn<SelectorModel, 'ready'>,
  {
    key: 'clusterName',
    label: 'Cluster',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterUuid, clusterName, clusterId }) =>
        clusterUuid
          ? routes.cluster.managed.qbert.detail.path({ id: clusterUuid })
          : clusterName
          ? routes.cluster.managed.capi.details.path({ id: clusterId })
          : null,
    }),
  } as GridViewColumn<SelectorModel, 'clusterName'>,
  {
    key: 'isMaster',
    label: 'Role',
    width: 'small',
    formatFn: (isMaster, node) =>
      isNil(node.uuid) || isUnassignedNode(node) ? '' : isMaster ? 'Master' : 'Worker',
  } as GridViewColumn<SelectorModel, 'isMaster'>,
  { key: 'usage', label: 'Resource Utilization', CellComponent: StatsCell } as GridViewColumn<
    SelectorModel,
    'resourceUtilization'
  >,
  { key: 'primaryIp', label: 'Network Interfaces', CellComponent: NetworkInterfacesCell },
  { key: 'nodeKubeVersion', label: 'K8s Version' },
]

const batchActions: GridBatchActionSpec<SelectorModel>[] = [
  {
    cond: isAdmin,
    icon: 'headset',
    label: 'Remote Support',
    BatchActionButton: getGridDialogButton(RemoteSupportDialog),
  },
  {
    cond: ([node]) => !node.isAuthorized,
    icon: 'plus-circle',
    label: 'Authorize',
    BatchActionButton: getGridDialogButton(NodeAuthDialog),
  },
  {
    cond: ([node]) => node.isAuthorized && !node.clusterUuid,
    icon: 'trash-alt',
    label: 'Deauthorize',
    BatchActionButton: getGridDialogButton(NodeDeAuthDialog, null, ([cluster], isDisabled) => ({
      tooltip: isDisabled
        ? "You can only de-authorize a node when it's not associated with any cluster. Detach the node from the cluster first."
        : null,
    })),
  },
  {
    cond: ([node]) => node.isAuthorized && node.cloudProviderType === CloudProviders.BareOS,
    icon: 'minus-hexagon',
    label: 'Force Remove',
    BatchActionButton: getGridDialogButton(
      NodeForceRemoveDialog,
      null,
      ([cluster], isDisabled) => ({
        tooltip: isDisabled
          ? 'Force removal of a node is only available for BareOS cluster nodes.'
          : null,
      }),
    ),
  },
]

const searchTargets = ['name', 'uuid']

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'KubevirtNodes',
  listTablePrefs,
)
const columnsOrder = ['uuid', 'name', 'clusterName']

const defaultParams: Params = {}

const dropdownFilters = [
  {
    key: 'status',
    label: 'Status',
    FilterComponent: StringMultiDropdownFilter,
    filterComponentProps: {
      label: 'Status',
    },
    filterComponentOptionsPropName: 'dropdownOptions',
    getOptionsFn: (items) => {
      const options = items?.map((item) => {
        const ready = item?.ready
        const label = readyLabelMap[ready] || 'Unknown'
        return {
          label,
          value: ready,
        }
      })
      return uniq(options)
    },
    equalityComparerFn: (item, value) => {
      const hasSome = value?.some((status) => item?.ready === status)
      return hasSome
    },
  },
]

export default function NodesListPage() {
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { message, loading, reload } = useListAction(listClusters, {
    params,
  })
  const data = useSelectorWithParams(kubevirtNodesSelector, params)

  return (
    <>
      <DocumentMeta title="Kubevirt Nodes" />
      <ListContainer<ModelDataKey, any>
        dataKey={DataKeys.Nodes}
        searchTargets={searchTargets}
        uniqueIdentifier="uuid"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        getParamsUpdater={getParamsUpdater}
        showItemsCountInLabel
        batchActions={batchActions}
        columnsOrder={columnsOrder}
        dropdownFilters={dropdownFilters}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
