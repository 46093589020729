import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React, { useMemo } from 'react'
import Text from 'core/elements/Text'
import { ILiveMigrationSelector } from './model'

interface IMigrations {
  active: ILiveMigrationSelector[]
  pending: ILiveMigrationSelector[]
  past: ILiveMigrationSelector[]
}

export default function CapiDetailsPageHeader({ liveMigrations = {} as IMigrations }) {
  const classes = useStyles()

  const categorizedMigrations = useMemo(
    () => ({
      active: liveMigrations?.active,
      pending: liveMigrations?.pending,
      succeeded: liveMigrations?.past?.filter((lm) => lm?.status?.phase === 'Succeeded'),
      failed: liveMigrations?.past?.filter((lm) => lm?.status?.phase === 'Failed'),
    }),
    [liveMigrations],
  )

  return (
    <header className={classes.headerBar}>
      <div className={classes.info}>
        <Text variant="subtitle2">Active</Text>
        <Text variant="h2" className={classes.count}>
          {categorizedMigrations?.active?.length}
        </Text>
      </div>
      <div className={classes.info}>
        <Text variant="subtitle2">Pending</Text>
        <Text variant="h2" className={classes.count}>
          {categorizedMigrations?.pending?.length}
        </Text>
      </div>
      <div className={classes.info}>
        <Text variant="subtitle2">Succeeded</Text>
        <Text variant="h2" className={classes.count}>
          {categorizedMigrations?.succeeded?.length}
        </Text>
      </div>
      <div className={classes.info}>
        <Text variant="subtitle2">Failed</Text>
        <Text variant="h2" className={classes.count}>
          {categorizedMigrations?.failed?.length}
        </Text>
      </div>
    </header>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  headerBar: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(264px, 1fr))',
    height: '100px',
    margin: '0px 0px 24px 0px',
    backgroundColor: theme.components.card.background,
    width: '100%',
  },
  info: {
    display: 'grid',
    borderLeft: `1px solid ${theme.components.card.border}`,
    padding: theme.spacing(2, 3),
    marginRight: theme.spacing(2),
  },
  count: {
    fontWeight: 'normal',
  },
}))
