import { AppSelector } from 'app/store'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { findClusterName } from 'k8s/util/helpers'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { complement, isNil, pipe, propEq, propSatisfies } from 'ramda'
import { IInstanceTypeSelector } from './instance-types-model'
import { IClusterInstanceTypeSelector } from './cluster-instance-types-model'

export const instanceTypesSelector: AppSelector<IInstanceTypeSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.InstanceTypes>(
    DataKeys.InstanceTypes,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  (instanceTypes, clusters): IInstanceTypeSelector[] => {
    return instanceTypes
      .map((instanceType) => ({
        ...instanceType,
        id: instanceType?.metadata?.uid,
        name: instanceType?.metadata?.name,
        clusterName: findClusterName(clusters, instanceType.clusterId),
        namespace: instanceType?.metadata?.namespace,
        created: instanceType?.metadata?.creationTimestamp,
      }))
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)

export const clusterInstanceTypesSelector: AppSelector<IClusterInstanceTypeSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.ClusterInstanceTypes>(
    DataKeys.ClusterInstanceTypes,
    ['clusterId'],
    ['clusterId'],
  ),
  allClustersSelector,
  (clusterInstanceTypes, clusters): IClusterInstanceTypeSelector[] => {
    return clusterInstanceTypes
      .map((clusterInstanceType) => ({
        ...clusterInstanceType,
        id: clusterInstanceType?.metadata?.uid,
        name: clusterInstanceType?.metadata?.name,
        clusterName: findClusterName(clusters, clusterInstanceType.clusterId),
        created: clusterInstanceType?.metadata?.creationTimestamp,
      }))
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)

type CombinedInstanceTypeSelector = IInstanceTypeSelector | IClusterInstanceTypeSelector

export const allInstanceTypesSelector = createSharedSelector(
  instanceTypesSelector,
  clusterInstanceTypesSelector,
  (instanceTypes, clusterInstanceTypes): CombinedInstanceTypeSelector[] => {
    return [...instanceTypes, ...clusterInstanceTypes]
  },
)
