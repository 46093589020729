import React, { useCallback, useMemo } from 'react'
import Text from 'core/elements/Text'
import { migrateVirtualMachineInstance } from '../new-actions'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Modal from 'core/elements/modal'

const MigrateVmiDialog = ({ rows: [virtualMachine], onClose }) => {
  const vmi = useMemo(
    () => (virtualMachine?.kind === 'VirtualMachine' ? virtualMachine?.vmi : virtualMachine),
    [virtualMachine],
  )
  const { update: migrateFn, updating: migratingVirtualMachine } = useUpdateAction(
    migrateVirtualMachineInstance,
  )
  const handleAction = useCallback(async () => {
    await migrateFn({
      ...vmi,
      vmiName: vmi.name,
    })
    onClose(true)
  }, [vmi])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title="Migrate VMI"
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={migratingVirtualMachine}>
            Cancel
          </Button>
          <Button onClick={handleAction} loading={migratingVirtualMachine}>
            Migrate VMI
          </Button>
        </>
      }
    >
      <Text variant="body2">Please confirm you want to migrate this Virtual Machine Instance.</Text>
    </Modal>
  )
}

export default MigrateVmiDialog
