import React, { useMemo } from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { listNetworks } from 'k8s/components/networking/network/actions'
import { networksSelector } from 'k8s/components/networking/network/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { projectAs } from 'utils/fp'

interface Props {
  clusterId: string
  namespace: string
}

export default function NetworkPicklist({
  clusterId,
  namespace,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const { loading } = useListAction(listNetworks, {
    params: {
      clusterId,
      namespace,
    },
    requiredParams: ['clusterId', 'namespace'],
  })
  const networks = useSelectorWithParams(networksSelector, {
    clusterId,
    namespace,
  })
  const items = useMemo(() => {
    return [...projectAs({ label: 'name', value: 'name' }, networks)]
  }, [networks])

  return (
    <AsyncDropdown
      name="network"
      label="Network"
      items={items}
      formField
      loading={loading}
      {...rest}
    />
  )
}
