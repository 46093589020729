import React, { useEffect } from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import ListTableField from 'core/components/validatedForm/ListTableField'
import RadioFields from 'core/components/validatedForm/radio-fields'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { allInstanceTypesSelector } from '../instance-types/selectors'
import { listInstanceTypes, listClusterInstanceTypes } from '../instance-types/actions'
import UnitPicklist from './UnitPicklist'
import { IInstanceTypeSelector } from '../instance-types/instance-types-model'
import PicklistField from 'core/components/validatedForm/DropdownField'
import Alert from 'core/components/Alert'

const useStyles = makeStyles<Theme>((theme) => ({
  specInput: {
    width: '100% !important',
  },
  withUnits: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  unitsDropdown: {
    position: 'relative',
    bottom: 1,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    margin: theme.spacing(3, 0),
  },
  table: {},
}))

const specifyCustom = [
  {
    key: 'custom',
    value: 'custom',
    label: 'Specify custom values for CPU and Memory',
  },
]

const selectPreset = [
  {
    key: 'useInstanceType',
    value: 'useInstanceType',
    label: 'Instance Types',
  },
]

const columns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'spec.cpu.guest',
    label: 'vCPUs',
  },
  {
    id: 'spec.memory.guest',
    label: 'Memory',
  },
  {
    id: 'spec.cpu.dedicatedCPUPlacement',
    label: 'Dedicated CPU Placement',
    render: (val: boolean) => (val ? 'Enabled' : 'Disabled'),
  },
  {
    id: 'spec.memory.hugepages.pageSize',
    label: 'Huge Pages',
    render: (val: string) => (val ? val : 'N/A'),
  },
  {
    id: 'namespace',
    label: 'Scope',
    render: (val: string) => (val ? `Namespace: ${val}` : 'Cluster Scoped'),
  },
]

export const ResourceStep = ({ wizardContext, setWizardContext, onNext }) => {
  const classes = useStyles({})

  const { loading: loadingInstanceTypes, reload: reloadInstanceTypes } = useListAction(
    listInstanceTypes,
    {
      params: {
        clusterId: wizardContext.clusterId,
      },
      requiredParams: ['clusterId'],
    },
  )
  const {
    loading: loadingClusterInstanceTypes,
    reload: reloadClusterInstanceTypes,
  } = useListAction(listClusterInstanceTypes, {
    params: {
      clusterId: wizardContext.clusterId,
    },
    requiredParams: ['clusterId'],
  })
  const instanceTypes = useSelectorWithParams(allInstanceTypesSelector, {
    clusterId: wizardContext.clusterId,
    // currently will never filter by namespace unless using global params
    // namespace: wizardContext.namespace,
    useGlobalParams: false,
  })

  // Explicit typing to get around IClusterInstanceType not having namespace
  const namespaceInstanceTypes = instanceTypes.filter((instanceType: IInstanceTypeSelector) => {
    if (instanceType?.namespace) {
      return instanceType.namespace === wizardContext.namespace
    }
    return true
  })

  // Because using the wizard summary yaml display, need to do some wizard context
  // management to display the data correctly
  useEffect(() => {
    if (wizardContext?.resourceOption === 'custom') {
      setWizardContext({
        instanceTypeTemplateObj: null,
        cpuTemplateObj: wizardContext?.vcpus ? { cores: wizardContext?.vcpus } : null,
        memoryTemplateObj: wizardContext?.ram
          ? { guest: `${wizardContext?.ram}${wizardContext?.ramUnit}` }
          : null,
      })
    } else if (wizardContext?.resourceOption === 'useInstanceType') {
      setWizardContext({
        instanceTypeTemplateObj: wizardContext?.instanceType?.[0]
          ? {
              kind: wizardContext?.instanceType?.[0]?.kind,
              name: wizardContext?.instanceType?.[0]?.name,
            }
          : null,
        cpuTemplateObj: null,
        memoryTemplateObj: null,
      })
    }
  }, [wizardContext?.resourceOption])

  return (
    <ValidatedForm
      onSubmit={setWizardContext}
      initialValues={wizardContext}
      triggerSubmit={onNext}
      elevated={false}
    >
      <FormFieldSection title="Select method of configuring resources.">
        <Text variant="body2">
          Specify custom values for VCPU and RAM or select a pre-defined instance type.
        </Text>
        <hr className={classes.divider} />
        <RadioFields
          id="resourceOption"
          options={specifyCustom}
          value={wizardContext.resourceOption}
          onChange={(value) =>
            setWizardContext({
              resourceOption: value,
            })
          }
        />
        <TextField
          id="vcpus"
          label="Cores"
          onChange={(value) => setWizardContext({ vcpus: value, cpuTemplateObj: { cores: value } })}
          value={wizardContext.vcpus}
          type="number"
          className={classes.specInput}
          min={1}
          disabled={wizardContext?.resourceOption !== 'custom'}
          required={wizardContext?.resourceOption === 'custom'}
        />
        <div className={classes.withUnits}>
          <TextField
            id="ram"
            label="Memory"
            onChange={(value) =>
              setWizardContext({
                ram: value,
                memoryTemplateObj: {
                  guest: `${value}${wizardContext?.ramUnit}`,
                },
              })
            }
            value={wizardContext.ram}
            type="number"
            min="1"
            disabled={wizardContext?.resourceOption !== 'custom'}
            required={wizardContext?.resourceOption === 'custom'}
          />
          <PicklistField
            DropdownComponent={UnitPicklist}
            id="ramUnit"
            onChange={(value) =>
              setWizardContext({
                ramUnit: value,
                memoryTemplateObj: {
                  guest: `${wizardContext?.ram}${value}`,
                },
              })
            }
            value={wizardContext.ramUnit}
            className={classes.unitsDropdown}
            disabled={wizardContext?.resourceOption !== 'custom'}
            required={wizardContext?.resourceOption === 'custom'}
          />
        </div>
        <hr className={classes.divider} />

        <RadioFields
          id="resourceOption"
          options={selectPreset}
          value={wizardContext.resourceOption}
          onChange={(value) =>
            setWizardContext({
              resourceOption: value,
            })
          }
        />
        <Alert
          variant="primary"
          message={
            <Text variant="body2">
              <b>Note:</b> Instance types created outside of the web application with dedicated CPU
              placement or hugepages enabled will not work with the current version of Kubevirt.
            </Text>
          }
        />
        <div>
          <ListTableField
            id="instanceType"
            data={namespaceInstanceTypes}
            loading={loadingInstanceTypes || loadingClusterInstanceTypes}
            columns={columns}
            onChange={(value) => {
              setWizardContext({
                instanceType: value,
                // Don't update the yaml spec unless active
                instanceTypeTemplateObj:
                  wizardContext?.resourceOption === 'useInstanceType'
                    ? { kind: value?.[0]?.kind, name: value?.[0]?.name }
                    : null,
              })
            }}
            value={wizardContext.instanceType}
            onReload={() => {
              reloadInstanceTypes(true, true)
              reloadClusterInstanceTypes(true, true)
            }}
            required={wizardContext?.resourceOption === 'useInstanceType'}
          />
        </div>
      </FormFieldSection>
    </ValidatedForm>
  )
}

export default ResourceStep
