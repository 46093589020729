import React from 'react'
import { switchCase } from 'utils/fp'
import { capitalizeString } from 'utils/misc'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, allKey, TablePrefsParams } from 'app/constants'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import ExternalLink from 'core/components/ExternalLink'
import AlarmDetailsLink from 'k8s/components/alarms/AlarmDetailsLink'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { alertsSelector } from 'k8s/components/alarms/selectors'
import { GridViewColumn } from 'core/elements/grid/Grid'
import ListContainer from 'core/containers/ListContainer'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip'
import Badge from 'core/elements/badge'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

type ModelDataKey = DataKeys.Alerts
type SelectorModel = ArrayElement<ReturnType<typeof alertsSelector>>

type Params = {
  severity?: string
  chartTime?: string
  status?: string
  showNeverActive?: boolean
}

const defaultParams: Params = {
  severity: allKey,
  chartTime: '24.h',
  status: 'Active',
  showNeverActive: false,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'KubevirtAlerts',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName', 'exportedNamespace']

export const NameCell = ({ value, item }) => <AlarmDetailsLink display={value} alarm={item} />

export const TimeCell = ({ value }) => (value ? <DateAndTime value={value} /> : <div>N/A</div>)

const GrafanaLink = ({ value }) => (
  <ExternalLink className="no-wrap-text" icon="chart-line" url={value}>
    Grafana
  </ExternalLink>
)

const chartKeys = [
  {
    name: 'warning',
    color: 'yellow.200',
    badge: 'warning' as const,
    icon: 'exclamation-triangle',
  },
  {
    name: 'critical',
    color: 'yellow.300',
    badge: 'danger' as const,
    icon: 'engine-warning',
  },
  {
    name: 'fatal',
    color: 'red.300',
    badge: 'error' as const,
    icon: 'skull-crossbones',
  },
]

export const SeverityTableCell = ({ value }) => {
  const key = chartKeys.find((key) => key.name === value)
  return key ? <Badge variant={key.badge} text={capitalizeString(value)} /> : <div>{value}</div>
}

const SummaryCell = ({ value }) => (
  <Tooltip message={value}>
    <Text variant="body2" maxWidth={350} lineClamp={2}>
      {value ? value : 'N/A'}
    </Text>
  </Tooltip>
)

export const getAlarmSeverityStatus = (severity) => {
  const variant = switchCase(
    {
      warning: 'warning',
      critical: 'danger',
      fatal: 'error',
    },
    'unknown',
  )(severity)
  return { variant, label: capitalizeString(severity || 'unknown') }
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    display: false,
    key: 'fingerprint',
    label: 'Fingerprint',
  },
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: NameCell,
  },
  {
    key: 'severity',
    label: 'Severity',
    CellComponent: createGridStatusCell({
      dataFn: getAlarmSeverityStatus,
    }),
  },
  {
    key: 'updatedAt',
    label: 'Time',
    CellComponent: TimeCell,
  },
  {
    key: 'summary',
    label: 'Rule Summary',
    CellComponent: SummaryCell,
  },
  { key: 'status', label: 'Status', formatFn: (val: string) => (val ? val : 'N/A') },
  {
    key: 'grafanaLink',
    label: 'Open in Grafana',
    CellComponent: GrafanaLink,
  },
  {
    key: 'clusterName',
    label: 'Cluster',
    formatFn: (val: string) => (val ? val : 'N/A'),
  },
  {
    key: 'labels',
    label: 'Labels',
    disableSorting: true,
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
  {
    display: false,
    key: 'startsAt',
    label: 'Starts At',
    CellComponent: TimeCell,
  },
  {
    display: false,
    key: 'endsAt',
    label: 'Ends At',
    CellComponent: TimeCell,
  },
]

export default function KubevirtAlarmsChart({ alarms, loadingAlarms }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <ListContainer<ModelDataKey, SelectorModel>
        showBreadcrumbs={false}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loadingAlarms}
        // onRefresh={reloadAlerts}
        data={alarms}
        columns={columns}
        label="Alarms"
      />
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    height: '100%',
    '& .grid-container': {
      height: '100%',
    },
  },
}))
