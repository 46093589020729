import React, { useCallback } from 'react'
import Text from 'core/elements/Text'
import { powerVirtualMachine } from './new-actions'
import Button from 'core/elements/button'
import { capitalizeString } from 'utils/misc'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Modal from 'core/elements/modal'

const VirtualMachinePowerOperationDialog = ({ rows: [virtualMachine], params, onClose }) => {
  const { update: powerFn, updating: poweringVirtualMachine } = useUpdateAction(powerVirtualMachine)
  const title = `${capitalizeString(params?.operation)} VM "${virtualMachine?.name}"?`
  const handleAction = useCallback(async () => {
    await powerFn({
      ...virtualMachine,
      operation: params?.operation,
    })
    onClose(true)
  }, [virtualMachine])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={title}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={poweringVirtualMachine}>
            Cancel
          </Button>
          <Button onClick={handleAction} loading={poweringVirtualMachine}>
            {capitalizeString(params?.operation)} VM
          </Button>
        </>
      }
    >
      <Text variant="body2">Please confirm you want to {params?.operation} this VM.</Text>
    </Modal>
  )
}

export default VirtualMachinePowerOperationDialog
