import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import Bugsnag from 'utils/bugsnag'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'
import { trackEvent } from 'utils/tracking'

const { qbert } = ApiClient.getInstance()

export const instanceTypeActions = ActionsSet.make<DataKeys.InstanceTypes>({
  uniqueIdentifier: 'metadata.uid',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.InstanceTypes,
  cacheKey: DataKeys.InstanceTypes,
})

export const listInstanceTypes = instanceTypeActions.add(
  new ListAction<DataKeys.InstanceTypes, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Instance Types', params)
    return qbert.getInstanceTypes(params.clusterId)
  }),
)

export const createInstanceType = instanceTypeActions.add(
  new CreateAction<DataKeys.InstanceTypes, { clusterId: string; namespace: string; body: any }>(
    async ({ clusterId, namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new Instancetype', {
        clusterId,
        namespace,
        body,
      })
      const instanceType: any = await qbert.createInstanceType(clusterId, namespace, body)
      trackEvent('Create New Instancetype', {
        clusterId,
        namespace,
        name: instanceType?.metadata?.name,
      })
      return instanceType
    },
  ),
)

export const updateInstanceType = instanceTypeActions.add(
  new UpdateAction<
    DataKeys.InstanceTypes,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
    }
  >(async ({ clusterId, namespace, name, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Instancetype', {
      clusterId,
      namespace,
      name,
      body,
    })

    const updatedInstanceType = await qbert.updateInstanceType({ clusterId, namespace, name, body })
    trackEvent('Update Instancetype', { clusterId, namespace, name })
    return updatedInstanceType
  }),
)

export const deleteInstanceType = instanceTypeActions.add(
  new DeleteAction<DataKeys.InstanceTypes, { clusterId: string; namespace: string; name: string }>(
    async ({ clusterId, namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Instancetype', { clusterId, namespace, name })
      await qbert.deleteInstanceType(clusterId, namespace, name)
    },
  ),
)

export const clusterInstanceTypeActions = ActionsSet.make<DataKeys.ClusterInstanceTypes>({
  uniqueIdentifier: 'metadata.uid',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.ClusterInstanceTypes,
  cacheKey: DataKeys.ClusterInstanceTypes,
})

export const listClusterInstanceTypes = clusterInstanceTypeActions.add(
  new ListAction<DataKeys.ClusterInstanceTypes, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Cluster Instance Types', params)
    return qbert.getClusterInstanceTypes(params.clusterId)
  }),
)

export const createClusterInstanceType = clusterInstanceTypeActions.add(
  new CreateAction<DataKeys.ClusterInstanceTypes, { clusterId: string; body: any }>(
    async ({ clusterId, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new Cluster Instancetype', {
        clusterId,
        body,
      })
      const clusterInstanceType: any = await qbert.createClusterInstanceType(clusterId, body)
      trackEvent('Create New Cluster Instancetype', {
        clusterId,
        name: clusterInstanceType?.metadata?.name,
      })
      return clusterInstanceType
    },
  ),
)

export const updateClusterInstanceType = clusterInstanceTypeActions.add(
  new UpdateAction<
    DataKeys.ClusterInstanceTypes,
    {
      clusterId: string
      name: string
      body: unknown
    }
  >(async ({ clusterId, name, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Cluster Instancetype', {
      clusterId,
      name,
      body,
    })

    const updatedClusterInstanceType = await qbert.updateClusterInstanceType({
      clusterId,
      name,
      body,
    })
    trackEvent('Update Cluster Instancetype', { clusterId, name })
    return updatedClusterInstanceType
  }),
)

export const deleteClusterInstanceType = clusterInstanceTypeActions.add(
  new DeleteAction<DataKeys.ClusterInstanceTypes, { clusterId: string; name: string }>(
    async ({ clusterId, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Cluster Instancetype', {
        clusterId,
        name,
      })
      await qbert.deleteClusterInstanceType(clusterId, name)
    },
  ),
)
