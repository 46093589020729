import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {}

// values match with moment's add/subtract API for use in actions.js
const items = [
  { label: 'masquerade', value: 'masquerade' },
  { label: 'bridge', value: 'bridge' },
]

export default function PodNetworkBindingPicklist(props: PropsWithAsyncDropdown<Props>) {
  return (
    <AsyncDropdown
      name="podNetworkBindingType"
      label="Pod Network Binding Type"
      items={items}
      formField
      {...props}
    />
  )
}
