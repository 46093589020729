import React, { useCallback, useEffect, useRef } from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import ListTableField from 'core/components/validatedForm/ListTableField'
import AccessModePicklist from './AccessModePicklist'
import StorageClassesPicklist from 'k8s/components/storage/StorageClassesPicklist'
import uuid from 'uuid'
import clsx from 'clsx'
import { noneKey } from 'app/constants'
import CodeBlock from 'core/components/CodeBlock'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { listDataVolumes } from 'k8s/components/storage/data-volumes/new-actions'
import { dataVolumesSelector } from 'k8s/components/storage/data-volumes/selectors'
import { listPersistentVolumeClaims } from 'k8s/components/storage/persistent-volume-claims/new-actions'
import { persistentVolumeClaimsSelector } from 'k8s/components/storage/persistent-volume-claims/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { requiredValidator, minValueValidator } from 'core/utils/fieldValidators'
import { listPods } from 'k8s/components/pods/new-actions'
import PicklistField from 'core/components/validatedForm/DropdownField'
import VmStorageOptionPicklist from './VmStorageOptionPicklist'
import { remove, update } from 'ramda'
import { getVolumeJson } from './helpers'

const useStyles = makeStyles<Theme>((theme) => ({
  storageFields: {
    display: 'grid',
    gap: 16,
  },
  iconAction: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, max-content)',
    marginTop: 16,
    alignItems: 'center',
    gap: 8,
  },
  deleteAction: {
    margin: '24px 0px',
  },
  icon: {
    color: theme.palette.primary.main,
    fontWeight: 900,
  },
  clickable: {
    cursor: 'pointer',
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    margin: theme.spacing(3, 0),
  },
}))

const storageSizeValidators = [minValueValidator(1), requiredValidator]

const dataVolumeColumns = [
  { id: 'name', label: 'Name' },
  {
    id: 'spec.pvc.resources.requests.storage',
    label: 'Size',
    render: (value, item) => {
      return value ? value : item?.spec?.storage?.resources?.requests?.storage
    },
  },
]

const pvcColumns = [
  { id: 'name', label: 'Name' },
  { id: 'spec.resources.requests.storage', label: 'Size' },
]

export const StorageStep = ({ wizardContext, setWizardContext, onNext }) => {
  const classes = useStyles({})

  const { loading: dataVolumesLoading, reload: reloadDataVolumes } = useListAction(
    listDataVolumes,
    {
      params: {
        clusterId: wizardContext.clusterId,
        namespace: wizardContext.namespace,
      },
      requiredParams: ['clusterId', 'namespace'],
    },
  )
  const dataVolumes = useSelectorWithParams(dataVolumesSelector, {
    clusterId: wizardContext.clusterId,
    namespace: wizardContext.namespace,
    useGlobalParams: false,
  })

  const { loading: pvcsLoading, reload: reloadPvcs } = useListAction(listPersistentVolumeClaims, {
    params: {
      clusterId: wizardContext.clusterId,
    },
    requiredParams: ['clusterId'],
  })
  const pvcs = useSelectorWithParams(persistentVolumeClaimsSelector, {
    clusterId: wizardContext.clusterId,
    namespace: wizardContext.namespace,
    useGlobalParams: false,
  })

  const { loading: podsLoading, reload: reloadPods } = useListAction(listPods, {
    params: {
      clusterId: wizardContext.clusterId,
      namespace: wizardContext.namespace,
    },
    requiredParams: ['clusterId', 'namespace'],
  })

  const addStorageDisk = useCallback(() => {
    setWizardContext({
      storageDisks: [
        ...wizardContext.storageDisks,
        {
          rowId: uuid.v4(),
          accessMode: 'ReadWriteMany',
          sourceType: 'httpUrl',
          storageClass: noneKey,
        },
      ],
    })
  }, [wizardContext.storageDisks, setWizardContext])

  useEffect(() => {
    if (!wizardContext.storageDisks.length) {
      addStorageDisk()
    }
  }, [])

  // Only run the processing of disks for yaml template upon successful
  // submission of step -- to keep this up-to-date constantly is too much processing
  const validatorRef = useRef(null)
  const setupValidator = (validate) => {
    validatorRef.current = { validate }
  }

  const submitStep = useCallback(async () => {
    const isValid = await validatorRef.current.validate()
    if (!isValid) {
      return false
    }
    const { diskSpec, volumeSpec, dataVolumeTemplates } = getVolumeJson({
      storageDisks: wizardContext.storageDisks,
      vmName: wizardContext.name,
      cloudInit: wizardContext.cloudInit,
    })
    setWizardContext({
      diskTemplatesObj: diskSpec,
      volumesObj: volumeSpec,
      dataVolumeTemplates: dataVolumeTemplates?.length ? dataVolumeTemplates : null,
    })
    return true
  }, [wizardContext?.storageDisks])

  useEffect(() => {
    onNext(submitStep)
  }, [submitStep])

  return (
    <ValidatedForm
      // Because setting of storageDisks is done within the component, and
      // having onSubmit will reset the value
      // onSubmit={setWizardContext}
      initialValues={wizardContext}
      triggerSubmit={setupValidator}
      elevated={false}
    >
      <div>
        {wizardContext.storageDisks.map((disk, idx) => (
          <>
            {idx > 0 && (
              <div className={clsx(classes.iconAction, classes.deleteAction)}>
                <FontAwesomeIcon
                  className={classes.icon}
                  onClick={() => {
                    setWizardContext({
                      storageDisks: remove(idx, 1, wizardContext?.storageDisks),
                    })
                  }}
                  size="xl"
                >
                  minus-circle
                </FontAwesomeIcon>
                <Text
                  variant="body2"
                  className={classes.clickable}
                  onClick={() => {
                    setWizardContext({
                      storageDisks: remove(idx, 1, wizardContext?.storageDisks),
                    })
                  }}
                >
                  Delete Storage Disk
                </Text>
              </div>
            )}
            <FormFieldSection title={idx > 0 ? 'Storage Disk' : 'Storage Disk (Required)'}>
              <div key={disk.rowId}>
                <div className={classes.storageFields}>
                  <PicklistField
                    DropdownComponent={VmStorageOptionPicklist}
                    id={`storageDisks.${idx}.sourceType`}
                    label="Select an option"
                    value={disk.sourceType}
                    onChange={(value) => {
                      const updatedDisk = {
                        ...disk,
                        sourceType: value,
                      }
                      setWizardContext({
                        storageDisks: update(idx, updatedDisk, wizardContext?.storageDisks),
                      })
                    }}
                    required
                  />
                  {disk.sourceType === 'httpUrl' && (
                    <TextField
                      id={`storageDisks.${idx}.httpUrl`}
                      label="HTTP URL"
                      onChange={(value) => {
                        const updatedDisk = {
                          ...disk,
                          httpUrl: value,
                        }
                        setWizardContext({
                          storageDisks: update(idx, updatedDisk, wizardContext?.storageDisks),
                        })
                      }}
                      value={disk.httpUrl}
                      required
                    />
                  )}
                  {disk.sourceType === 'registryUrl' && (
                    <TextField
                      id={`storageDisks.${idx}.registryUrl`}
                      label="Registry URL"
                      onChange={(value) => {
                        const updatedDisk = {
                          ...disk,
                          registryUrl: value,
                        }
                        setWizardContext({
                          storageDisks: update(idx, updatedDisk, wizardContext?.storageDisks),
                        })
                      }}
                      value={disk.registryUrl}
                      required
                    />
                  )}
                  {disk.sourceType === 'disk' && (
                    <>
                      <Text variant="body2">
                        You can run the virtctl image-upload command as below to upload a VM image
                        to a PVC or DataVolume:
                      </Text>
                      <CodeBlock>
                        {`# virtctl image-upload --pvc-name=<upload-pvc> --pvc-size=<1Gi> --image-path=</your/centos/image.qcow2>
    # virtctl image-upload dv dv-name --size=10Gi --image-path=/images/fedora30.qcow2`}
                      </CodeBlock>
                    </>
                  )}
                  {disk.sourceType === 'clone' && (
                    <div>
                      <ListTableField
                        id={`storageDisks.${idx}.selectedPvcs`}
                        data={pvcs}
                        loading={pvcsLoading || podsLoading}
                        columns={pvcColumns}
                        onChange={(value) => {
                          const updatedDisk = {
                            ...disk,
                            selectedPvcs: value,
                          }
                          setWizardContext({
                            storageDisks: update(idx, updatedDisk, wizardContext?.storageDisks),
                          })
                        }}
                        value={disk.selectedPvcs}
                        onReload={() => {
                          reloadPvcs(true, true)
                          reloadPods(true, true)
                        }}
                        required
                      />
                    </div>
                  )}
                  {disk.sourceType === 'volume' && (
                    <ListTableField
                      id={`storageDisks.${idx}.selectedVolumes`}
                      data={dataVolumes}
                      loading={dataVolumesLoading}
                      columns={dataVolumeColumns}
                      onChange={(value) => {
                        const updatedDisk = {
                          ...disk,
                          selectedDataVolumes: value,
                        }
                        setWizardContext({
                          storageDisks: update(idx, updatedDisk, wizardContext?.storageDisks),
                        })
                      }}
                      value={disk.selectedDataVolumes}
                      onReload={() => {
                        reloadDataVolumes(true, true)
                      }}
                      required
                    />
                  )}
                  <PicklistField
                    DropdownComponent={AccessModePicklist}
                    id={`storageDisks.${idx}.accessMode`}
                    label="Access Mode"
                    value={disk.accessMode}
                    onChange={(value) => {
                      const updatedDisk = {
                        ...disk,
                        accessMode: value,
                      }
                      setWizardContext({
                        storageDisks: update(idx, updatedDisk, wizardContext?.storageDisks),
                      })
                    }}
                  />
                  {['httpUrl', 'registryUrl', 'disk', 'blank'].includes(disk.sourceType) && (
                    <>
                      <TextField
                        className={classes.sizeField}
                        id={`storageDisks.${idx}.storageSize`}
                        label="Size (GiB)"
                        onChange={(value) => {
                          const updatedDisk = {
                            ...disk,
                            storageSize: value,
                          }
                          setWizardContext({
                            storageDisks: update(idx, updatedDisk, wizardContext?.storageDisks),
                          })
                        }}
                        value={disk.storageSize}
                        min={1}
                        type="number"
                        validations={storageSizeValidators}
                        required
                      />
                      {['httpUrl', 'registryUrl', 'blank'].includes(disk.sourceType) && (
                        <PicklistField
                          DropdownComponent={StorageClassesPicklist}
                          id={`storageDisks.${idx}.storageClass`}
                          label="Storage Class"
                          onChange={(value) => {
                            const updatedDisk = {
                              ...disk,
                              storageClass: value,
                            }
                            setWizardContext({
                              storageDisks: update(idx, updatedDisk, wizardContext?.storageDisks),
                            })
                          }}
                          clusterId={wizardContext?.clusterId}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </FormFieldSection>
            <hr className={classes.divider} />
          </>
        ))}
      </div>
      <div className={classes.iconAction}>
        <FontAwesomeIcon className={classes.icon} onClick={addStorageDisk} size="xl">
          plus-circle
        </FontAwesomeIcon>
        <Text variant="body2" className={classes.clickable} onClick={addStorageDisk}>
          Add Another Storage Disk
        </Text>
      </div>
    </ValidatedForm>
  )
}

export default StorageStep
